@import "../../../../styles/common.scss";

.customer {
  display: flex;
  align-items: center;
  flex: 1;
  min-width: 0;
}

.avatar {
  height: 35px;
  width: 35px;
  flex-shrink: 0;
}

.nameWrapper {
  overflow: hidden;
}

.customerName {
  cursor: pointer;
  transition: color 0.3s;
  white-space: pre-wrap;
  word-break: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  line-height: 20px;

  @include hover-supported {
    color: $theme-text-color-19;
  }
}

@media screen and (max-width: 767px) {
  .nameWrapper {
    max-width: calc(100% - 60px);
  }
}
