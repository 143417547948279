.scrollBar {
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    background-color: #ffffff;
    border-radius: 5px;
    width: 20px;
  }

  &::-webkit-scrollbar-track {
    background-color: #ffffff;
    border-radius: 7px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #aeb3ca;
    border-radius: 15px;
    border: 5px solid #ffffff;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
}
