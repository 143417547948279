$animation-duration: 1s;
$animation-color-1: #3d6deb;
$animation-color-2: #719bfb;
$animation-color-3: #c4d9fd;

.Loader {
  width: 24px;
  height: 24px;
  transform: rotate(10deg);
  animation: rotation $animation-duration infinite;

  &__shape {
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }

  &__shape1 {
    top: 0;
    left: 0;
    background-color: $animation-color-2;
    animation: changeColor4 $animation-duration infinite;
  }

  &__shape2 {
    top: 0;
    right: 0;
    background-color: $animation-color-1;
    animation: changeColor1 $animation-duration infinite;
  }

  &__shape3 {
    right: 0;
    bottom: 0;
    background-color: $animation-color-2;
    animation: changeColor2 $animation-duration infinite;
  }

  &__shape4 {
    bottom: 0;
    left: 0;
    background-color: $animation-color-3;
    animation: changeColor3 $animation-duration infinite;
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes changeColor1 {
  0% {
    background-color: $animation-color-1;
  }
  12.5% {
    background-color: $animation-color-2;
  }
  37.5% {
    background-color: $animation-color-3;
  }
  62.5% {
    background-color: $animation-color-2;
  }
  87.5% {
    background-color: $animation-color-1;
  }
}

@keyframes changeColor2 {
  0% {
    background-color: $animation-color-2;
  }
  12.5% {
    background-color: $animation-color-3;
  }
  37.5% {
    background-color: $animation-color-2;
  }
  62.5% {
    background-color: $animation-color-1;
  }
  87.5% {
    background-color: $animation-color-2;
  }
}

@keyframes changeColor3 {
  0% {
    background-color: $animation-color-3;
  }
  12.5% {
    background-color: $animation-color-2;
  }
  37.5% {
    background-color: $animation-color-1;
  }
  62.5% {
    background-color: $animation-color-2;
  }
  87.5% {
    background-color: $animation-color-3;
  }
}

@keyframes changeColor4 {
  0% {
    background-color: $animation-color-2;
  }
  12.5% {
    background-color: $animation-color-1;
  }
  37.5% {
    background-color: $animation-color-2;
  }
  62.5% {
    background-color: $animation-color-3;
  }
  87.5% {
    background-color: $animation-color-2;
  }
}
