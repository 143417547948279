@import "../../../../styles/common.scss";

.headRow {
  background-color: $theme-background-color-13;
  width: 100%;
  height: 100%;
  padding: 0 14px;

  @include screen-custom-max($md-1 - 1) {
    padding: 0 14px 0 10px;
  }
}

.collapseMode {
  padding-right: 40px;
}

.tableActions {
  div {
    flex-grow: 0 !important;
    position: sticky;
  }
}

.headCellBackground {
  background-color: $theme-background-color-13;

  @media screen and (max-width: 1100px) and (min-width: 1024px) {
    padding: 10px 5px !important;
  }
}

.headCell {
  cursor: pointer;

  @include hover-supported {
    transition: color 0.2s;
    color: $theme-text-color-1;

    svg {
      path {
        transition: fill 0.2s;
        fill: $theme-fill-color-15;
      }
    }
  }

  &.isColumnSticky {
    background-color: inherit;
    z-index: 100;
  }
}

.isSorted {
  color: $theme-text-color-1 !important;

  & > span > svg {
    path {
      fill: $theme-fill-color-15;
    }
  }
}
