@import "../../styles/common.scss";
@import "../../styles/variables.scss";

.wrapper {
  position: relative;
  flex-shrink: 0;
  user-select: none;

  .button {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 12px 16px;
    border-radius: 6px;
    background-color: $theme-background-color-7;
    color: $theme-text-color-1;
    text-align: center;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    cursor: pointer;
    transition: all 0.2s ease-out;

    &:hover {
      background-color: $theme-background-color-4;
    }

    &.active {
      color: $theme-text-color-19;
      background-color: $theme-background-color-4;

      path {
        fill: $theme-fill-color-12 !important;
      }

      svg:last-child {
        transform: rotate(180deg);
      }
    }

    svg {
      transition: all 0.2s ease-out;

      path {
        fill: $theme-fill-color-11 !important;
      }
    }
  }

  .content {
    position: absolute;
    right: 0;
    top: calc(100% + 8px);
    border-radius: 8px;
    width: 380px;
    background-color: $theme-background-color-13;
    box-shadow: 2px 10px 50px 0px #3f4b8021;
    z-index: 100;
    overflow: hidden;
    touch-action: none;

    &.hidden {
      display: none;
    }

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px;

      div:first-child {
        color: $theme-text-color-1;
        font-family: Lato;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
      }

      div:last-child {
        color: $theme-text-color-2;
        font-family: Lato;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
      }
    }

    .items {
      display: flex;
      flex-direction: column;

      .item {
        position: relative;
        padding: 12px 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
        background-color: $theme-background-color-13;

        &.dragging {
          z-index: 10;
          box-shadow: 0px 0px 20px 0px #3f4b8021;
          transform-origin: right;
          transform: rotateZ(0.5deg);
          cursor: grabbing;

          * {
            cursor: grabbing !important;
          }
        }

        svg {
          cursor: grab;
        }
      }
    }
  }
}

@media screen and (max-width: ($sm - 1px)) {
  .wrapper {
    display: none;
  }
}
