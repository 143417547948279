@import "../styles/variables.scss";

.layout {
  margin-left: 72px;
  background: #f8f9fc;
  transition: margin 0.2s linear;

  &.menuOpen {
    margin-left: 216px;
  }
}

@media screen and (max-width: $md-1 - 1px) {
  .layout {
    margin-left: 0 !important;
  }
}
