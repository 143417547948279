@import "../../styles/common.scss";

.warnings {
  padding: 16px;
  overflow: auto;

  .header {
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    line-height: 16px;

    .title {
      color: $theme-text-color-0;
      font-weight: 700;
    }
    .count {
      color: $theme-text-color-2;
      font-weight: 400;
    }
  }
}
