@import "../../../../styles/common.scss";

.TotalFuelUsed {
  margin-top: 40px;

  &__Title {
    font: normal normal bold 16px/18px Lato;
    color: #191919;
  }
  &__Description {
    font: normal normal normal 16px/18px Lato;
    color: #95a1b2;
  }

  &__CountGraph {
    background: #575fcf;
    width: 100%;
    height: 13px;
    padding: 2px;
    border-radius: 7px;
  }

  &__CountIdlingGraph {
    background: #dbeefe;
    height: 100%;
    border-radius: 7px;
  }

  &__GraphLegendDot {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin-right: 15px;
  }

  &__GraphLegendDotTotal {
    background: #575fcf 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 15px #575fcf;
  }

  &__GraphLegendDotIdle {
    background: #dbeefe 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 15px #dbeefe;
  }

  &__GraphLegendLabel {
    font: normal normal normal 14px/13px Lato;
    color: $theme-text-color-1;
  }
  &__GraphLegendValue {
    font: normal normal bold 18px/34px Lato;
    letter-spacing: 0px;
    color: #191919;
  }
  &__GraphLegendUnit {
    font: normal normal normal 14px/34px Lato;
    letter-spacing: 0px;
    color: #191919;
  }

  &__EngineOperation {
    background: #f8f9fc;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
  }

  &__EngineOperationTitle {
    font: normal normal medium 16px/39px Lato;
    letter-spacing: 0px;
    color: $theme-text-color-2;
    margin-top: 10px;
  }

  &__EngineOperationTime {
    font: normal normal bold 24px/41px Lato;
    color: #191919;
  }

  &__EngineOperationUnit {
    font: normal normal normal 20px/41px Lato;
    color: #191919;
  }

  &__GenericsItem {
    position: relative;
    margin-top: 1.5rem;
    width: 50%;

    &:before,
    &:after {
      content: "";
      position: absolute;
      background-color: #eaeaf3;
    }

    &:nth-child(1) {
      padding-right: 15px;
    }
    &:nth-child(2) {
      padding-left: 15px;
      &:before {
        left: 0;
        top: 0;
        height: 75%;
        width: 1px;
      }
    }
    &:nth-child(3) {
      padding-right: 15px;
      &:before {
        left: 0;
        top: -1.5rem;
        height: 1px;
        width: 75%;
      }
    }
    &:nth-child(4) {
      padding-left: 15px;
      &:before {
        left: 0;
        top: 0;
        height: 75%;
        width: 1px;
      }
      &:after {
        right: 0;
        top: -1.5rem;
        height: 1px;
        width: 75%;
      }
    }
    &:nth-child(5) {
      padding-right: 15px;
      &:before {
        left: 0;
        top: -1.5rem;
        height: 1px;
        width: 75%;
      }
    }
  }

  &__GenericLabel {
    font: normal normal medium 14px/20px Lato;
    letter-spacing: 0px;
    color: $theme-text-color-2;
  }

  &__GenericValue {
    font: normal normal bold 18px/34px Lato;
    color: #191919;
  }

  &__GenericUnit {
    font: normal normal normal 16px/34px Lato;
    color: #191919;
  }

  &__HorizontalSeparator,
  &__VerticalSeparator {
    position: absolute;
    background: #ebeff4;
  }

  &__HorizontalSeparator {
    height: 1px;
    width: 75%;
    bottom: -15px;
  }

  &__VerticalSeparator {
    width: 1px;
    height: 75%;
    top: 0;
    right: 0;
  }

  .GenericItemsContainer {
    display: flex;
    flex-flow: wrap;
  }
}

@media screen and (max-width: 1023px) {
  .TotalFuelUsed {
    &__GenericsItem {
      width: 33.33%;

      &:nth-child(1) {
        padding-right: 30px;
      }
      &:nth-child(2) {
        padding-left: 30px;
        padding-right: 30px;
        &:before {
          left: 0;
          top: 0;
          height: 75%;
          width: 1px;
        }
      }
      &:nth-child(3) {
        padding-left: 30px;
        padding-right: 0;
        &:before {
          left: 0;
          top: 0;
          width: 1px;
          height: 75%;
        }
      }
      &:nth-child(4) {
        padding-left: 0;
        padding-right: 30px;
        &:before {
          display: none;
        }
        &:after {
          left: 0;
          top: -1.5rem;
          height: 1px;
          width: 75%;
        }
      }
      &:nth-child(5) {
        padding-left: 30px;
        padding-right: 30px;
        &:before {
          left: 0;
          top: 0;
          height: 75%;
          width: 1px;
        }
        &:after {
          right: 25%;
          top: -1.5rem;
          height: 1px;
          width: 50%;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .TotalFuelUsed {
    &__MobileNameContainer {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px !important;
    }

    &__CountGraph {
      margin-bottom: 20px;
    }

    &__Description {
      font-size: 14px;
      margin-top: 7px;
    }

    &__GraphLegendItem {
      flex-direction: column;
      align-items: flex-start !important;
      margin-top: 10px;
    }

    &__GraphLegendDot {
      margin-right: 10px;
    }

    &__GraphLegendValue {
      margin-left: 26px;
    }

    &__EngineOperation {
      margin-top: 30px !important;
      margin-bottom: 0px !important;
    }

    &__GenericsItem {
      width: 100%;
      padding: 0 !important;
      &:before,
      &:after {
        display: none;
      }
      &:not(:last-child):after {
        display: block;
        height: 1px;
        width: 100%;
        top: auto;
        bottom: 0;
        left: 0;
      }
    }
  }
}
