@import "../../../styles/common.scss";

.CustomerSearch {
  &.CustomerSearch__search {
    width: 400px;
    margin-right: 24px;
    margin-left: 24px;

    @media screen and (max-width: 1023px) {
      margin-right: 20px;
      margin-left: 0;
      flex: 400 0 auto;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
      flex: 100 0 auto;
      order: 3;
      margin-right: 0;
      margin-top: 24px;
    }
  }
}
