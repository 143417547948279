@import "../../../../styles/colors.scss";
@import "../../../../styles/variables.scss";

.SpinnerWrapper {
  min-height: 567px;
}

.ServicesHistory {
  max-height: 584px;
  height: calc(100% - 24px) !important;
  padding-bottom: 5px !important;

  &__no-data {
    padding-bottom: 24px !important;

    .Container {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;

      .icon {
        margin: 65px auto 20px;
        height: 160px;
        width: 160px;
      }

      > :last-child {
        display: table-cell;
        margin: 0 auto 55px;
        color: $theme-text-color-4;
        text-align: center;
      }
    }
  }

  .Header {
    display: flex;
    margin-top: 20px;
    align-items: center;

    .Column {
      display: flex;
      align-items: center;
      color: $theme-text-color-2;
      font-size: 14px;
      width: 90px;
      max-width: 25%;
      margin-left: 16px;
      cursor: pointer;
      user-select: none;

      &:first-child {
        margin-left: 0;
        margin-right: auto;
        width: auto;
      }

      &:has(.Visible) {
        color: $theme-text-color-1;
      }

      .Icon {
        margin-left: 7px;
        min-width: 8px;
        opacity: 0.6;

        path {
          fill: $theme-fill-color-11;
        }

        &.Visible {
          opacity: 1;

          path {
            fill: $theme-fill-color-15;
          }
        }

        &.Asc {
          transform: rotate(180deg);
        }
      }
    }
  }

  .RowsContainer {
    overflow: auto;
    margin-top: 18px;
    margin-right: -25px;
    padding-right: 17px;

    .Row {
      display: flex;
      padding-top: 20px;
      padding-bottom: 20px;
      align-items: center;
      border-bottom: 1px solid $theme-border-color-4;

      &:first-child {
        padding-top: 0;
      }

      &:nth-last-child(2) {
        border-bottom: none;
      }

      .Name {
        margin-right: auto;
        font: normal normal bold 14px/13px Lato;
        color: $theme-text-color-0;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .Value {
        width: 90px;
        max-width: 25%;
        margin-left: 16px;
        font: normal normal normal 14px/13px Lato;
        color: $theme-text-color-1;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        flex-shrink: 0;
      }
    }
    .MobileRow {
      display: none;
    }
    .MobileRowExpanded {
      display: none;
    }
  }
}

@media screen and (max-width: 1400px) {
  .ServicesHistory {
    max-height: 423px;

    &__no-data {
      min-height: 160px;
    }

    .Header {
      .Column {
        width: 164px;

        &:last-child {
          margin-left: 30px;
        }
      }
    }

    .RowsContainer {
      margin-top: 26px;
      .Row {
        .Value {
          width: 164px;

          &:last-child {
            margin-left: 30px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .ServicesHistory {
    .Header {
      .Column {
        width: 115px;
      }
    }

    .RowsContainer {
      .Row {
        .Value {
          width: 115px;
        }
      }
    }
  }
}

@media screen and (max-width: $sm - 1) {
  .ServicesHistory {
    padding-bottom: 0px !important;
    max-height: none;

    .Header {
      display: none;
    }

    .RowsContainer {
      margin-top: 10px;
      margin-right: 0;
      padding-right: 0;
      overflow: visible;

      .Row {
        display: none;
      }

      .MobileRow {
        display: flex;
        justify-content: space-between;
        cursor: pointer;

        &:last-child {
          border: none;
        }

        .Name {
          width: 80%;
        }
        .Value {
          display: none;
        }

        .ArrowIcon {
          width: 10px;
          height: 10px;
          background-image: url(../../../../assets/icons/arrow-gray-down.svg);
          background-repeat: no-repeat;
          background-position: center;
        }
      }

      .MobileRowExpanded {
        display: block;
        position: relative;
        cursor: pointer;
        border-radius: 5px;
        padding: 20px 25px;
        box-shadow: 2px 4px 24px #3f4b8019;
        margin: 0 -25px;
        background-color: white;

        .Name {
          font: normal normal bold 14px/13px Lato;
          color: $theme-text-color-0;
        }

        .ArrowIcon {
          position: absolute;
          top: 20px;
          right: 25px;
          width: 10px;
          height: 10px;
          background-image: url(../../../../assets/icons/arrow-blue-up.svg);
          background-repeat: no-repeat;
          background-position: center;
        }

        .Parameter {
          display: flex;
          margin-top: 20px;

          .Name {
            color: $theme-text-color-2;
            font: normal normal normal 13px/13px Lato;
          }

          .Value {
            margin-left: 32px;
            font: normal normal normal 14px/13px Lato;
            color: $theme-text-color-1;
          }
        }
      }
    }
  }
}
