/* Webfont: Lato-Regular */
@font-face {
  font-family: "Lato";
  src: url("../assets/fonts/Lato-Regular.woff") format("woff");
  font-style: normal;
  font-weight: 400;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Medium */
@font-face {
  font-family: "Lato";
  src: url("../assets/fonts/Lato-Medium.woff") format("woff");
  font-style: normal;
  font-weight: 500;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Semibold */
@font-face {
  font-family: "Lato";
  src: url("../assets/fonts/Lato-Semibold.woff") format("woff");
  font-style: normal;
  font-weight: 600;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Bold */
@font-face {
  font-family: "Lato";
  src: url("../assets/fonts/Lato-Bold.woff") format("woff");
  font-style: normal;
  font-weight: 700;
  text-rendering: optimizeLegibility;
}
