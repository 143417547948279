@import "../../styles/common.scss";
@import "../../styles/variables.scss";

.dtc {
  width: 800px;
  max-height: 300px;
  max-width: calc(100vw - 260px);
  padding: 24px 16px 16px;
  overflow: hidden auto;
}

@media screen and (max-width: ($md-1 - 1px)) {
  .dtc {
    max-width: calc(100vw - 24px);
  }
}

@media screen and (max-width: ($sm - 1px)) {
  .dtc {
    width: 420px;
    max-width: calc(100vw - 30px);
  }
}
