@import "../../styles/common.scss";

.wrapper {
  background-color: $theme-background-color-13;
  border-bottom: 1px solid $theme-border-color-4;
  border-left: 1px solid $theme-border-color-4;
  height: 65px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .logoContainerMobile {
    display: none;
  }

  .searchContainer {
    position: relative;
    margin: 0 40px;
    flex-grow: 1;

    .searchWrapper {
      display: flex;
      justify-content: flex-end;

      [data-lastpass-icon-root] {
        display: none;
      }

      .search {
        box-shadow: 2px 4px 24px 0px rgba(63, 75, 128, 0.1);
        transition: none;

        &:hover,
        &:focus-within {
          box-shadow: 0 0 0 1px #118af9;
        }
      }

      .icon,
      .button {
        display: none;
      }

      .results {
        position: absolute;
        top: calc(100% + 4px);
        z-index: 20;
        width: 100%;
        max-height: 340px;
        overflow: auto;
        box-shadow: 2px 10px 50px 0px rgba(63, 75, 128, 0.1);
        border-radius: 8px;
        background-color: $theme-background-color-13;

        .noData,
        .loader {
          padding: 30px 0;
        }

        .item {
          display: flex;
          gap: 16px;
          align-items: center;
          padding: 12px 16px;
          cursor: pointer;

          &:hover {
            background-color: $theme-background-color-20;
          }

          .icon {
            height: 35px;
            width: 35px;
            flex-shrink: 0;
          }

          .data {
            width: 100%;

            .name {
              font-family: Lato;
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: 16px;
              color: $theme-text-color-1;
            }

            .details,
            .shop {
              display: flex;
              gap: 7px;
              align-items: center;
              flex-wrap: wrap;
              margin-top: 4px;
              font-family: Lato;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 16px;
              color: $theme-text-color-2;

              .dot {
                width: 3px;
                height: 3px;
                border-radius: 50%;
                background-color: $theme-background-color-0;
                flex-shrink: 0;

                &:last-child {
                  display: none;
                }
              }
            }

            .shop {
              flex-wrap: nowrap;
              div {
                overflow: hidden;
                text-overflow: ellipsis;
                text-wrap: nowrap;

                &:first-child {
                  flex-shrink: 0;
                }
              }
            }

            .highlight {
              display: inline-block;
              background-color: $theme-background-color-19;
            }
          }
        }
      }
    }
  }

  .buttonInstallDevice {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 0 40px 0 0;
    height: 44px;
    flex-shrink: 0;
    border-radius: 6px;
    padding: 0 16px;
    background-color: $theme-background-color-7;
    user-select: none;
    cursor: pointer;
    transition: background-color 0.2s ease-out;

    &:hover {
      background-color: $theme-background-color-4;
    }
    &:active {
      background-color: $theme-background-color-5;
    }

    .icon {
      width: 20px;
      height: 24px;
    }

    .title {
      color: $theme-text-color-1;
      font-family: Lato;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
    }
  }
}

.shopsDropDown {
  width: 377px;

  .selectWrapper {
    position: relative;
    height: 100%;
  }

  .options {
    position: absolute;
    width: 100%;
    max-height: 390px;
    background-color: #fff;
    box-shadow: 2px 10px 50px #3f4b8021;
    border-radius: 7px;
    z-index: 100;
    top: 71px;
    overflow-y: auto;
    scroll-padding-top: 20px;

    .optionsHeader {
      padding: 25px 20px 20px;

      .title {
        margin-top: 30px;
        font: bold 14px/18px Lato;
        color: $theme-text-color-1;
      }
    }
  }

  .option {
    height: 60px;
    padding-left: 20px;
    font: 600 14px Lato;
    color: $theme-text-color-1;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;

    &:hover {
      background-color: #f2f4f8;
    }
  }

  .shopInfo {
    font: bold 14px Lato;
    padding-left: 40px;
    background-color: #2a3340;
    color: #ffffff;
    position: relative;
    cursor: pointer;
    height: 100%;
    border-left: 1px solid #95a1b2;
    transition: background-color 0.25s ease-out;

    &:hover {
      background-color: #3b4451;
    }

    .shopWrapper {
      padding-top: 15px;
      .shopName {
        font: bold 14px/22px Lato;
      }

      .shopAddress {
        font: normal 12px/18px Lato;
      }
    }
  }
  .focused {
    background-color: #3b4451;
    color: #dbeefe;

    ~ .arrowIcon {
      transform: rotate(180deg);
      transition: all 0.25s ease-out;
    }
  }
}
.arrowIcon {
  position: absolute;
  width: 9px;
  height: 6px;
  right: 40px;
  top: 30px;
  transform: rotate(0deg);
  transition: all 0.25s ease-in;
  pointer-events: none;

  path {
    fill: #ffffff;
  }
}

.hidden {
  display: none;
}

.menuUnderlay {
  display: none;
}

@media screen and (max-width: ($lg-1 - 1px)) {
  .wrapper {
    .buttonInstallDevice {
      margin: 0 24px 0 0;
    }

    .searchContainer {
      margin: 0 24px;
    }
  }
}

@media screen and (max-width: ($md-1 - 1px)) {
  .wrapper {
    border: none;
    height: 64px;
    background-color: $theme-background-color-42;

    .logoContainerMobile {
      display: flex;
      align-items: center;

      &.hidden {
        display: none;
      }

      .logo {
        margin-left: 16px;
        cursor: pointer;
      }

      .button {
        margin-left: 24px;
        border-radius: 6px;
        cursor: pointer;

        &.active {
          background-color: $theme-background-color-43;
        }
      }
    }

    .searchContainer {
      .search:not(.shown) {
        display: none;
      }

      .icon.shown {
        display: block;
        cursor: pointer;
        user-select: none;

        path {
          fill: $theme-fill-color-0;
        }
      }

      .button.shown {
        display: block;
        margin-left: 16px;
        color: $theme-text-color-22;
        font-family: Lato;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 45px;
        cursor: pointer;
        user-select: none;
      }
    }

    .buttonInstallDevice {
      margin-left: 10px;

      &.hidden {
        display: none;
      }
    }

    .menuUnderlay {
      position: fixed;
      top: 64px;
      left: 0;
      width: 100vw;
      height: calc(100vh - 64px);
      z-index: 1015;
      background-color: #626f85;
      opacity: 0.3;

      &.visible {
        display: block;
      }
    }
  }
}

@media screen and (max-width: ($sm - 1px)) {
  .wrapper {
    .logoContainerMobile {
      .logo {
        display: none;
      }

      .button {
        margin-left: 16px;

        &.active {
          display: none;
        }
      }
    }

    .searchContainer {
      .searchWrapper {
        justify-content: flex-start;
      }
    }

    .buttonInstallDevice {
      display: none;
    }

    .menuUnderlay {
      top: 0;
      height: 100vh;
    }
  }
}
