@import "./../../styles/common.scss";

.Toggle {
  position: relative;

  &__arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 18px;
    width: 30px;
    height: 30px;
    cursor: pointer;

    &--left {
      left: 15px;
    }

    &--right {
      right: 15px;
    }

    @include hover-supported {
      & > svg {
        fill: $theme-fill-color-12;
      }
    }
  }
}
