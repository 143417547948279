.EngineIcon__EngineWrapper {
  position: absolute;
  right: -5px;
  top: -5px;
  background: #fff;
  border-radius: 50%;
  box-shadow: 2px 3px 6px #3f4b8021;
  height: 30px;
  width: 30px;
}

@media screen and (max-width: 767px) {
  .EngineIcon__EngineWrapper {
    width: 40px;
    height: 40px;
    top: -10px;
    right: -10px;
  }

  .EngineImg {
    width: 27px;
  }
}
