@import "../../../../../styles/common.scss";

.HeaderShopsList {
  top: 72px;
  max-width: 375px;
  overflow: hidden;
  background-color: #ffffff;
  z-index: $z-index-header-shop-list;

  @include screen-sm {
    width: 375px;
  }

  ul {
    margin: 0;
  }
}

.scrollbar {
  max-height: 340px;
  overflow-y: auto;
  overflow-x: hidden;
}

.searchField {
  margin: 17px 20px 20px;
}

.noData {
  padding: 20px 0;
}

.rightPosition {
  right: 0;
  @include screen-custom-max(767px) {
    width: calc(100vw - 2px);
  }
}

.leftPosition {
  right: auto;
  @include screen-custom-max(767px) {
    width: calc(100vw - 2px);
  }
}

.arrowBack {
  cursor: pointer;
  align-self: flex-start;
  margin-top: 10px;
  margin-left: 20px;
  width: 20px;
  height: 20px;

  path {
    fill: $theme-fill-color-11;
  }
  &:hover {
    path {
      fill: $theme-fill-color-3;
    }
  }
}

.loaderWrapper {
  position: relative;
  height: 254px;

  .loader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.loadMoreWrapper {
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.tooltip {
  background: #222840 !important;
  &::after {
    border-top-color: #222840 !important;
  }
}
