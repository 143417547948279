@import "../../../../../styles/common.scss";

.noDataWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-height: 2000px;
  font-family: "Lato", sans-serif;

  &.noSearchDataCustomersWrapper {
    height: calc(100vh - 190px);
    min-height: 450px;
  }
  &.noSearchDataVehiclesWrapper {
    height: calc(100vh - 240px);
    min-height: 340px;

    .noSearchData {
      margin: 0;

      .title {
        margin-top: 32px;
      }
    }
  }
  &.noSearchDataAlertsWrapper {
    height: 100%;
    padding-bottom: 2px;
  }
  &.noSearchDataFiltersWrapper {
    min-height: 340px;
    margin: auto;

    .noSearchData {
      margin: 0;

      .title {
        margin-top: 20px;
      }
    }
  }
}

.noData {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-height: 420px;

  .title {
    font-size: 20px;
    font-weight: 700;
    color: $theme-text-color-4;
    margin-top: 30px;
    margin-bottom: 10px;
  }

  .message {
    white-space: pre-line;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    text-align: center;
    color: $theme-text-color-4;
  }

  &.noSearchData {
    margin-bottom: 68px;

    .title {
      line-height: 28px;
      color: $theme-text-color-4;
      margin-top: 24px;
      margin-bottom: 10px;
    }

    .message {
      color: $theme-text-color-4;
      font-weight: 400;
      line-height: 20px;
    }
  }
}

@include screen-custom-max($app-tablet) {
  .noDataWrapper {
    &.noSearchDataCustomersWrapper {
      height: calc(100vh - 200px);
    }
  }

  .noData {
    .title {
      margin-top: 20px;
    }
  }
}

@include screen-custom-max($app-mobile) {
  .noData {
    .title {
      margin-top: 20px;
    }
  }
}
