@import "./../../styles/common.scss";

.DatePicker__input__wrapper {
  position: relative;
  width: 100%;

  .DatePicker__icon {
    position: absolute;
    top: 13px;
    right: 15px;
    width: 20px;
    height: 20px;

    &--withLabel {
      top: 43px;
    }
  }

  .DatePicker__icon_blue {
    path,
    rect {
      fill: $theme-fill-color-12;
    }
  }

  .DatePicker__closeIcon {
    position: absolute;
    top: 16px;
    right: 50px;
    width: 14px;
    height: 14px;

    &--withLabel {
      top: 43px;
    }
    &:hover {
      path {
        fill: $theme-fill-color-12;
      }
    }
  }

  .DatePicker__label {
    font-size: 14px;
    line-height: 30px;
    color: $theme-text-color-2;
    font-family: "Lato";
    font-weight: 600;
    margin-left: 3px;
  }

  &:hover {
    cursor: pointer;
    & > .DatePicker__input {
      cursor: pointer;
      border-color: $theme-border-color-14;
      box-shadow: 0 0 0 1px $theme-shadow-color-0;
    }

    & > .DatePicker__icon {
      path,
      rect {
        fill: $theme-fill-color-12;
      }
    }
  }

  .DatePicker__input {
    @include font(14px, 36px, 700);
    font-family: "Lato";
    width: 100%;
    height: 45px;
    border-radius: 5px;
    border: 1px solid $theme-border-color-4;
    color: $theme-text-color-1;
    padding: 14px 50px 10px 20px;
    background-color: $theme-background-color-13;
    text-overflow: ellipsis;

    &::placeholder {
      font-family: "Lato";
      font-weight: 400;
      color: $theme-text-color-4;
    }

    &:focus {
      outline: none;
    }

    &_active {
      border-color: $theme-border-color-14;
      box-shadow: 0 0 0 1px $theme-shadow-color-0;
    }
  }
}
