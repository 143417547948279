/* mixin for general button styles */
@mixin button-general() {
  border-radius: 5px;
  transition: all 0.3s;
  cursor: pointer;

  .Button__text {
    @include font(14px, 24px, 700);
  }

  .Button__start-icon {
    display: flex;
    align-items: center;
    margin-right: 7px;
  }

  &:focus {
    outline: none;
  }

  @media only screen and (max-width: 1024px) {
    &.Button--onlyIcon {
      .Button__text {
        display: none;
      }

      .Button__start-icon {
        margin-right: 0;
      }
    }
  }
}

/* mixin for set button size (width, height) */
@mixin button-size($max-width: 152px, $height: 40px, $min-width: 152px) {
  max-width: $max-width;
  min-width: $min-width;
  width: 100%;
  height: $height;
}

/* mixin for actions (hover, pressed, disabled) */
@mixin button-actions(
  $hoverColor,
  $activeColor,
  $textColor: $theme-fill-color-0
) {
  &.Button--isLoad {
    pointer-events: none;
  }

  @include hover-supported {
    background: $hoverColor;
    color: $textColor;
  }

  &:active {
    background: $activeColor;
    color: $textColor;
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
}

/* mixin for loading state */
@mixin button-loading(
  $color: $theme-border-color-8,
  $colorWithOpacity: rgba(255, 255, 255, 0.3)
) {
  .Button__Loader,
  .Button__Loader:after {
    border-radius: 50%;
    width: 20px;
    height: 20px;
  }

  .Button__Loader {
    margin: 0 auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 3px solid $colorWithOpacity;
    border-right: 3px solid $colorWithOpacity;
    border-bottom: 3px solid $colorWithOpacity;
    border-left: 3px solid $color;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
  }

  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}
