@import "../../../styles/common.scss";

.counterWrapper {
  display: flex;
  align-items: center;
}

.inputContainer {
  display: inline-block;
  position: relative;

  &:hover {
    .input {
      border: 2px solid #118af9;
      padding: 13px 24px 13px 15px;
      color: #49505a;
    }

    .controls {
      right: 3px;
      top: 2px;
      height: 40px;
      width: 21px;
    }

    .controlButton {
      width: 21px;
    }
  }
}

.input {
  width: 100px !important;
  height: 44px !important;
  padding: 14px 23px 14px 16px;
  border: 1px solid #ebeff4;
  transition: border-color 0.3s;
  border-radius: 6px !important;
  font: normal normal 700 14px/16px Lato;
  color: #707d8f;

  &:hover,
  &:focus {
    border: 2px solid #118af9;
    padding: 13px 24px 13px 15px;
    box-shadow: none !important;
    outline: none;
    color: #49505a;

    + .controls {
      right: 3px;
      top: 2px;
      height: 40px;
      width: 21px;
    }

    + .counterButton {
      width: 21px;
    }
  }
}

.controls {
  position: absolute;
  right: 2px;
  top: 1px;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-left: 1px solid #ebeff4;
  height: 42px;
  width: 22px;
}

.controlButton {
  flex-wrap: wrap;
  width: 22px;
  height: 50%;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;

  &:hover,
  &:active {
    background-color: #dbeefe;

    svg {
      path {
        stroke: #118af9;
        fill: #118af9;
      }
    }
  }

  &.plus {
    border-bottom: 1px solid #ebeff4;
    border-top-right-radius: 4px;
  }

  &.minus {
    border-bottom-right-radius: 4px;
  }
}

.additionalLabel {
  text-align: left;
  font: normal normal normal 14px/29px Lato;
  color: $theme-text-color-1;
  margin-left: 15px;
}
