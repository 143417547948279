@import "../../../../styles/common.scss";

.tooltipIcon {
  margin-top: 1px;
}

.vehicleContent {
  display: flex;
  cursor: pointer;
  transition: fill 0.3s;

  &:hover {
    .tooltipIcon {
      path,
      circle {
        fill: #0879e1;
      }
    }
  }
}

.tooltip {
  padding: 0 !important;
  background-color: $theme-background-color-13;
  max-height: 355px;
  border-radius: 3px;
  box-shadow: 2px 10px 50px #3f4b8021;
}
