@import "../../../../../styles/common.scss";
@import "../../../../../styles/variables.scss";

li,
ul {
  list-style: none;
  padding: 0;
}

.SearchField {
  width: 100%;
  height: 45px;
  border: 1px solid $theme-border-color-4;
  background-color: #ffff;
  transition: all 0.3s;

  @include hover-supported {
    border: 1px solid $theme-border-color-14;
    box-shadow: 0 0 0 1px $theme-border-color-14;

    .SearchField__icon > svg {
      fill: #118af9;
    }
  }

  &:focus-within {
    border: 1px solid $theme-border-color-14;
    box-shadow: 0 0 0 1px $theme-border-color-14;

    &__icon > svg {
      fill: $theme-fill-color-12;
    }
  }

  &__icon {
    min-width: 48px;
    height: inherit;
    margin-left: 8px;
    & > svg {
      transition: all 0.3s;
    }
  }

  &__input {
    @include truncate-text();
    width: 100%;
    height: 41px;
    padding-top: 3px;
    border: 0;

    &::placeholder {
      font-size: 14px;
      font-weight: 400;
      color: $theme-text-color-4;
    }

    &:focus-visible {
      outline: none;
    }

    &.mobile {
      display: none;
    }
  }

  &__clearButton {
    width: 30px;
    height: 30px;
    cursor: pointer;

    @include hover-supported {
      svg {
        fill: $theme-text-color-19;
      }
    }
  }

  &__disabled {
    @include disabled-field();
  }
}

@media screen and (max-width: ($sm - 1px)) {
  .SearchField {
    &__input {
      display: none;
      &.mobile {
        display: initial;
      }
    }
  }
}
