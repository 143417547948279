@import "../../../../styles/common.scss";

.spinnerWrapper {
  min-height: 567px;
  position: relative;
}

.wrapper {
  margin-right: -20px;
  margin-top: 24px;
}

.range {
  position: relative;
  width: 100%;
  height: 10px;
  color: $theme-text-color-4;
}

.servicesContainer {
  height: 372px;
  overflow-y: scroll;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerButtons {
  display: flex;

  button {
    border: 1px solid $theme-border-color-4;
    width: 54px;
    padding: 9px 10px;
    font: normal normal bold 14px/14px Lato;
    background: $theme-background-color-13;
    color: $theme-text-color-4;
    outline: none;

    &:first-child {
      border-radius: 5px 0px 0px 5px;
    }

    &:last-child {
      border-radius: 0px 5px 5px 0px;
    }
  }
}

.active {
  background: $theme-background-color-19 !important;
  color: $theme-text-color-19 !important;
}

.RecommendedServiceLine {
  margin-right: 13px;

  &:first-child {
    margin-top: 0 !important;
  }

  &__label {
    color: $theme-text-color-2;
    font-size: 14px;
  }

  &__dueText {
    color: $theme-text-color-2;
    font-size: 12px;
  }

  &__value {
    color: #191919;
    font-size: 18px;
    font-weight: 700;
  }

  &__dimension {
    color: #191919;
    font-size: 14px;
  }

  &__bar {
    height: 6px;
    border-radius: 3px;
    background-color: #eceff1;
  }
}

@media screen and (max-width: 992px) {
  .servicesContainer {
    height: max-content;
    overflow-y: hidden;
  }

  .RecommendedServiceLine {
    margin-right: 20px;
    &__label {
      font-weight: 700;
      color: $theme-text-color-1;
    }
  }
}

@media screen and (max-width: 767px) {
  .RecommendedServiceLine {
    position: relative;
    margin-bottom: 50px;
    margin-top: 23px !important;
    &__label {
      margin-bottom: 10px;
    }
  }
  .MobileValue {
    position: absolute;
    top: 50px;
    left: 0;
  }
}

.ManageServicesButton {
  max-width: 100%;
  height: 40px;
  border: none;
  border-radius: 5px;
  font: normal normal bold 14px/24px Lato;
  color: $theme-text-color-1;
  background-color: $theme-background-color-7;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: $theme-background-color-4;
  }
  &:focus {
    outline: none;
  }
  &:active {
    background-color: $theme-background-color-5;
    color: $theme-text-color-19;
    outline: none;

    svg path {
      fill: $theme-fill-color-12;
    }
  }
}

.manageIcon {
  margin-right: 7px;
  path {
    fill: $theme-fill-color-11;
  }
}
