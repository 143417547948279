:global {
  .rc-tooltip {
    opacity: 1;
    z-index: 9999;
  }

  .rc-tooltip-inner {
    white-space: pre-line;
    color: #ffffff;
    text-align: center;
    padding: 10px 20px 8px !important;
    border-radius: 3px;
    min-height: auto;
    background-color: #262a3a;
  }

  .rc-tooltip-arrow {
    height: 10px;
    width: 10px;
    transform: translate(-50%, 0) rotate(45deg);
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.17);
    border-width: 10px 5px 0 !important;
    border-color: #262a3a !important;
    margin-left: 0 !important;
  }

  .rc-tooltip-placement-right {
    padding: 0 0 0 4px !important;
  }
}
