@import "../../../styles/common.scss";
@import "./index.scss";

// Danger Button
.Button {
  @include button-general();
  @include button-size();

  &.Button__danger {
    color: $theme-text-color-6;
    background-color: $theme-background-color-28;

    @include button-actions(
      $theme-background-color-27,
      $theme-background-color-26
    );
  }

  @include button-loading();
}
