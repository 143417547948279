@import "../../../styles/common.scss";

.CustomerCounter {
  color: $theme-text-color-2;

  &__totalValue {
    color: $theme-text-color-0;
    font-size: 20px;
    line-height: 18px;
    font-weight: 700;
  }
}
