:global {
  @import "./flex.scss";
  @import "./typography.scss";
  @import "./margin.scss";
  @import "./padding.scss";
  @import "./position.scss";
  @import "./border-radius.scss";
  @import "./box-shadow.scss";
  @import "./width.scss";
}
