@import "../../../../styles/common.scss";

.SpinnerWrapper {
  min-height: 200px;
}

.DtcCodes {
  .counters {
    margin-top: -8px;
    height: 40px;
  }

  &__SecondaryLabel {
    color: $theme-text-color-4;
    font-weight: 400;
  }

  &__ClearButton {
    width: 103px;
    height: 40px;
    margin-left: 30px;
    border-radius: 5px;
    border: none;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    color: $theme-text-color-19;
    background-color: #dbeefe;

    &:hover {
      background-color: $theme-background-color-18;
    }
    &:focus {
      outline: none;
    }
    &:active {
      background-color: $theme-background-color-17;
      outline: none;
    }

    &__Loader {
      background: url(../../../../assets/icons/loading.svg);
      width: 20px;
      height: 20px;
      animation: spin 1s linear infinite;
      margin: 0 auto;
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }

  &__States {
    display: flex;
    justify-content: flex-end;
    text-align: right;
    height: 25px;
  }

  .DtcCodesTable {
    &__Header {
      display: grid;
      grid-template-columns: 3fr 0.8fr 1.4fr 1fr 0.6fr 1.3fr 1fr;
      grid-column-gap: 25px;
      margin-top: 24px;
      align-items: center;
      border-bottom: 1px solid #ebeff4;
      padding-bottom: 14px;
    }

    &__ShadowRowWrapper {
      background-color: #fff;
      position: relative;
      box-shadow: 0px 11px 8px -10px #3f4b8019, 0px -11px 8px -10px #3f4b8019;

      & > div:last-child {
        padding-bottom: 30px;
      }

      &::before,
      &::after {
        content: "";
        width: 55px;
        height: 100%;
        position: absolute;
        background: white;
        z-index: 10;
      }

      &::before {
        left: -40px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        box-shadow: -2px -2px 4px -1px #3f4b8019, -2px 2px 3px -1px #3f4b8019;
      }
      &::after {
        right: -40px;
        top: 0;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        box-shadow: 2px -2px 4px -1px #3f4b8019, 2px 2px 3px -1px #3f4b8019;
      }
    }

    &__HeaderCell,
    &__CellAdditionalValue {
      font-size: 13px;
      color: $theme-text-color-2;
      margin-top: 2px;
    }
    &__Row {
      display: grid;
      grid-template-columns: 3fr 0.8fr 1.4fr 1fr 0.6fr 1.3fr 1fr;
      grid-column-gap: 25px;
      padding-top: 14px;
      padding-bottom: 14px;
      border-bottom: 1px solid #ebeff4;

      &:last-child {
        padding-bottom: 0;
        border: none;
      }
    }
    &__Cell {
      color: #191919;
      font-size: 14px;
      line-height: 21px;
      align-self: center;
      z-index: 20;
      min-width: 45px;
    }

    &__CellAccentValue {
      color: #191919;
      font-weight: 700;
    }

    &__CellTruncatedValue {
      overflow: hidden;
      text-overflow: ellipsis;
      text-overflow: -o-ellipsis-lastline;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      hyphens: auto;
      word-break: break-word;
      transition: max-height 0s ease-in-out;
    }

    &__CellMoreButton {
      color: #118af9;
      font-weight: 400;
      cursor: pointer;
    }

    &__FaultStateCell {
      min-width: 80px;
      border-radius: 20px;
      text-transform: uppercase;
      color: white;
      font-size: 12px;
      font-weight: 700;
    }

    &__Count {
      display: flex;
      justify-content: center;
      align-items: center;
      background: $theme-background-color-40;
      color: #fff;
      border-radius: 50%;
      height: 30px;
      width: 30px;
      line-height: 0;
    }

    &__NonActiveFaultStateCell {
      background-color: #f8b131;
    }

    &__ActiveFaultStateCell {
      background-color: #ff5151;
    }

    &__PendingFaultStateCell {
      background-color: $theme-background-color-39;
    }

    &__CollapseIcon {
      width: 10px;
      height: 10px;
      background-image: url(../../../../assets/icons/arrow-blue-up.svg);
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;
    }

    &__ExpandIcon {
      width: 10px;
      height: 10px;
      background-image: url(../../../../assets/icons/arrow-gray-down.svg);
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;
    }

    &__FreezeFrameIcon {
      margin-left: 5px;
      cursor: pointer;
      &_Active {
        path,
        circle {
          fill: $theme-fill-color-12;
        }
      }
    }

    &__FreezeFrame {
      width: 405px;
      max-height: 500px;
      background-color: white;
      border-radius: 3px;
      filter: drop-shadow(2px 10px 50px #3f4b8019);
      padding: 20px;
      position: absolute;
      transform: translate(calc(-50% + 33px), calc(-100% - 15px));

      &::after {
        content: "";
        position: absolute;
        left: calc(50% - 10px);
        bottom: -5px;
        height: 20px;
        width: 20px;
        background-color: white;
        transform: rotate(45deg) skew(5deg, 5deg);
      }

      &__Header {
        display: grid;
        grid-template-columns: 1.9fr 1fr;
        column-gap: 20px;
        margin-top: 10px;
      }

      &__ItemsContainer {
        max-height: 388px;
        overflow: auto;
        margin-top: 18px;
        margin-right: -15px;
        padding-right: 8px;
      }

      &__Row {
        display: grid;
        grid-template-columns: 1.9fr 1fr;
        column-gap: 20px;
        padding: 15px 0;
        border-bottom: 1px solid $theme-border-color-4;
        &:last-child {
          border: none;
          padding-bottom: 0;
        }
        &:first-child {
          padding-top: 0;
        }
      }
    }

    .MobileExpandedRow {
      display: none;
    }
  }

  @mixin counter {
    font-size: 12px;
    font-weight: 700;
    background-repeat: no-repeat;
    background-position: 20px 4px;
    background-size: 14px 14px;
    padding-left: 42px;
  }

  &__ActiveCounter {
    @include counter;
    color: #ff5151;
    background-image: url(../../../../assets/icons/attention.svg);
    padding-right: 20px;
  }

  &__PendingCounter {
    @include counter;
    color: $theme-text-color-4;
    background-image: url(../../../../assets/icons/hourglass.svg);
    border-left: 1px solid $theme-border-color-4;
  }

  &__InactiveCounter {
    @include counter;
    color: #f8b131;
    background-image: url(../../../../assets/icons/blocked-yellow.svg);
    border-left: 1px solid $theme-border-color-4;
    padding-right: 20px;
  }

  &__CounterValue {
    font-size: 18px;
    line-height: 18px;
    font-weight: 700;
  }

  .noData {
    display: flex;
    flex-direction: column;
    margin: 52px auto 55px;
    color: $theme-text-color-4;
    text-align: center;

    .icon {
      margin: 0px auto 20px;
      height: 160px;
      width: 160px;
    }
  }
}

@media screen and (max-width: 1400px) {
  .DtcCodes {
    .DtcCodesTable {
      &__Header {
        grid-column-gap: 10px;
      }

      &__Row {
        grid-column-gap: 10px;
      }

      &__Cell {
        min-width: 0;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .DtcCodes {
    .DtcCodesTable {
      &__Header {
        grid-template-columns: 3fr 0.8fr 1.4fr 1fr 0.6fr 1.3fr 1fr;
        grid-column-gap: 20px;
        margin-top: 24px;
        align-items: center;
      }

      &__Row {
        grid-template-columns: 3fr 0.8fr 1.4fr 1fr 0.6fr 1.3fr 1fr;
        grid-column-gap: 20px;
      }

      &__FaultStateCell {
        max-width: 90px;
      }

      &__ShadowRowWrapper {
        > div:last-child {
          padding-bottom: 30px;
        }
      }

      &__FreezeFrameIcon {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .MobileDisplayNone {
    display: none !important;
  }

  .DtcCodes {
    .DtcCodesTable {
      &__Header {
        display: flex;
        justify-content: space-between;
      }

      &__HeaderCell {
        &:nth-child(n + 3) {
          display: none;
        }
        &:nth-child(2) {
          padding-right: 40px;
        }
      }

      &__Row {
        display: flex;
        padding-bottom: 10px;
        padding-top: 10px;
      }

      &__Cell {
        &:first-child {
          flex-grow: 1;
        }
        &:not(:first-child) {
          flex-shrink: 0;
        }
        &:nth-child(n + 3) {
          display: none;
        }
        &:last-child {
          > div:first-child {
            display: none;
          }
        }
      }

      &__CollapseIcon {
        z-index: 20;
      }

      &__ShadowRowWrapper {
        &::before {
          left: -30px;
        }
        &::after {
          right: -30px;
        }
        .DtcCodesTable {
          &__Row {
            &:nth-child(n + 2) {
              display: none;
            }
            flex-wrap: wrap;
          }

          &__Cell {
            display: initial;
            &:first-child {
              max-width: calc(100% - 90px);
            }
            &:nth-child(2) {
              padding-left: 20px;
              padding-right: 18px;
            }
          }
        }
      }
      .MobileExpandedRow {
        display: flex;
        flex-flow: wrap;
        align-items: center;
        padding: 20px 0;

        .MobileExpandedCell {
          display: flex;
          width: 100%;
          z-index: 20;
          margin-top: 25px;

          .MobileCellName {
            width: 50%;
            font-size: 13px;
            color: $theme-text-color-2;
          }

          .MobileCellValue {
            width: 50%;
            display: flex;
            .DtcCodesTable__CellAdditionalValue {
              margin-top: 0;
              margin-left: 5px;
            }
          }
        }
      }
    }
  }
}
