@import "../../../../styles/common.scss";

.Warnings {
  margin-top: 38px;

  &__Title_Wrapper {
    line-height: 20px;
  }

  &__Title {
    font: normal normal bold 16px/18px Lato;
    color: #191919;
  }

  &__Count {
    font: normal normal normal 16px/18px Lato;
    color: $theme-text-color-4;
  }

  &__Item {
    border-radius: 7px;
    overflow: hidden;
    min-height: 100px;
    display: flex;

    .IconBlock {
      min-width: 102px;
      @include flex-center();
    }
    .LabelBlock {
      padding: 10px 20px;
      @include flex-center();
      font: normal normal bold 14px/22px Lato;
    }

    &.PopupWarning {
      min-height: 57px;
      margin-top: 15px;

      .IconBlock {
        min-width: 92px;
      }
      .LabelBlock {
        padding: 5px 20px;
      }
    }

    &.WarningYellow {
      border: 1px solid #f8b131;
      color: #e1950d;
      margin-top: 20px;

      .IconBlock {
        background-color: #fff3dc;
        border-right: 1px solid #f8b131;
      }

      &.PopupWarning {
        margin-top: 15px;

        .IconBlock {
          min-width: 92px;
        }
      }
    }

    &.WarningRed {
      border: 1px solid #d7282f;
      color: #d7282f;
      margin-top: 20px;

      .IconBlock {
        background-color: #ffd7dc;
        border-right: 1px solid #d7282f;
      }

      &.PopupWarning {
        margin-top: 15px;

        .IconBlock {
          min-width: 92px;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .Warnings {
    &__Item {
      flex-direction: column;
      min-height: 202px;

      .IconBlock {
        height: 100px;
      }

      .LabelBlock {
        flex-grow: 1;
        text-align: center;
      }

      &.PopupWarning {
        margin-top: 15px;
        flex-direction: row;
        min-height: 57px;

        .IconBlock {
          min-width: 92px;
          height: auto;
        }

        .LabelBlock {
          text-align: left;
          justify-content: left;
        }
      }
    }

    .WarningYellow {
      .IconBlock {
        border-bottom: 1px solid #f8b131;
        border-right: 0;
      }
    }

    .WarningRed {
      .IconBlock {
        border-bottom: 1px solid #d7282f;
        border-right: 0;
      }
    }
  }
}
