@import "../../../../styles/common.scss";

.mobileRow {
  padding: 0 25px 20px;

  .row {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }

  .row + .row {
    margin-top: 40px;
  }

  .row:first-child {
    margin-top: 10px;
    align-items: center;
  }

  .row:nth-child(2) {
    margin-top: 30px;
    align-items: center;
  }
}

.rowMargin {
  margin-bottom: 10px;
}
