@import "../../../styles/common.scss";

.HeaderShopToggle {
  max-width: 92px;
  min-width: 92px;
  width: 100%;
  height: 100%;
  padding: 0;
  transition: background-color 0.2s ease-out;

  &.hidden {
    display: none;
  }

  @include screen-sm {
    max-width: 112px;
    min-width: 112px;
    border-left: 1px solid $theme-border-color-0;

    &.isBackgroundHover:hover {
      background-color: $theme-background-color-43;
    }
    &.isActive {
      background-color: $theme-background-color-43;
    }
  }

  @include screen-md-1 {
    max-width: 318px;
    min-width: 318px;
    border-left: 1px solid $theme-border-color-4;

    &.isBackgroundHover:hover {
      background-color: $theme-background-color-20;
    }

    &.isActive {
      background-color: $theme-background-color-20;
    }
  }

  @include screen-lg-1 {
    max-width: 350px;
    min-width: 350px;
  }

  &__button {
    width: 100%;
    height: 100%;
    background: transparent;
    cursor: pointer;
    padding: 0 16px 0 12px;

    @include screen-sm {
      padding: 0 24px 0 23px;
    }
    @include screen-lg-1 {
      padding: 0 40px 0 39px;
    }
  }

  &__shopLogo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #ffffff;

    & > img {
      width: 100%;
      height: 100%;
      padding: 20%;
    }

    @include screen-sm {
      width: 44px;
      height: 44px;
    }

    @media screen and (max-width: 767px) {
      width: 42px;
      height: 42px;
    }
  }

  &__info {
    display: none;

    @include screen-md-1 {
      display: block;
    }
  }

  &__shopName {
    @include truncate-text();
    margin-bottom: 4px;
    color: $theme-text-color-0;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;

    &.isActive {
      color: $theme-text-color-19;
    }
  }

  &__shopAddress {
    @include truncate-text();
    color: $theme-text-color-2;
    font-family: Lato;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }

  &__arrow {
    display: block;
    margin-left: 8px;
    width: 12px;
    height: 12px;
    padding: 1px;

    path {
      fill: $theme-fill-color-0;
    }

    &.isRotated {
      transform: rotate(-180deg);
    }

    @include screen-md-1 {
      margin-left: 16px;

      path {
        fill: $theme-fill-color-11;
      }

      &.isRotated {
        transform: rotate(-180deg);

        path {
          fill: $theme-fill-color-12;
        }
      }
    }
  }
}

.companyLogo {
  > div {
    width: 100%;
    height: 100%;

    > img {
      width: 100%;
      height: 100%;
    }
  }
}
