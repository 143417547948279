@import "./common.scss";

.Modal {
  max-width: 100%;
}

.Modal__Dialog {
  width: 460px;
}

.Modal__Content {
  background-color: #f8f9fc !important;
  padding: 40px;
  border: none;
  border-radius: 8px;

  @media (min-width: 576px) {
    margin: auto;
    position: relative;

    &.Modal__Content__Confirm {
      max-width: initial;
    }
  }

  &.Modal__Content__Confirm {
    @media (min-width: 767px) {
      min-width: 640px;
      margin: auto;
      position: relative;
      left: -68px;
    }
  }

  &.Modal__Content__Alert {
    padding-bottom: 24px;
  }
}

.Modal__Content__Alerts {
  background-color: #f8f9fc !important;
  padding: 40px 40px 20px;
  border-width: 0;
  min-width: 596px;
  margin: auto;
  position: relative;
  left: -48px;
}

.Modal__Header {
  text-align: center;
  display: flex;
  justify-content: center;
}

.Modal__CarIcon {
  height: 40px;
  margin-bottom: 12px;
}

.Modal__RemoveVehicleIcon,
.Modal__DeleteAlertIcon {
  width: 200px;
  height: 200px;
  margin-bottom: 15px;
}

.Modal__Title,
.Modal__AlertTitle {
  font: normal normal 700 30px/30px Lato;
  color: #191919;
  padding: 9px 0 11px;
}

.Modal__ConfirmTitle {
  font: normal normal 700 30px/40px Lato;
  color: #191919;
  padding-top: 4px;
  padding-bottom: 10px;
}

.Modal__Confirm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Modal__ConfirmSubtitle {
  font: normal normal 400 16px/24px Lato;
  color: #707d8f;
  text-align: center;
  white-space: pre-wrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;

  span {
    color: #191919;
    font-weight: bold;
    white-space: nowrap;
  }
}

.Modal__Subtitle {
  font: normal normal normal 14px/22px Lato;
  color: $theme-text-color-2;
  padding-bottom: 12px;
}

.Modal__CloseIcon {
  width: 14px;
  position: absolute;
  right: 25px;
  top: 25px;
  &:hover {
    cursor: pointer;
    path {
      fill: #0879e1;
    }
  }

  path {
    fill: $theme-fill-color-11;
  }
}

.Modal__Footer {
  border-top: none !important;
  padding: 40px 0 0;

  &.Modal__Footer__Alert {
    padding: 40px 0 16px;
  }

  &.Modal__Footer__Confirm {
    justify-content: center;
  }

  &.Modal__Footer__Users {
    justify-content: right;
  }
}

.Modal__CancelBtn {
  height: 44px;
  width: calc((100% - 24px) / 2);
  margin: 0 24px 0 0 !important;
  font: normal normal 700 14px/16px Lato;
  letter-spacing: 0;
  border-radius: 6px;
  color: #49505a;
  background-color: #ebeff4;
  border: none !important;
  pointer-events: all;
  transition: background 0.3s ease-out;

  &:hover {
    background-color: #d9dfe8;
  }

  &:focus {
    outline: none;
  }

  &:active {
    background-color: #c8d0da;
  }

  &.Modal__CancelBtn__Confirm {
    width: 152px;
  }

  &.Modal__CancelBtn__Users {
    width: 165px;
  }
}

.Modal__SubmitBtn {
  background: #d7282f;
  border: none !important;
  border-radius: 6px;
  color: $theme-text-color-6;
  height: 44px;
  width: calc((100% - 24px) / 2);
  font: normal normal 700 14px/24px Lato;
  outline: none;
  margin: 0 !important;
  pointer-events: all;
  transition: background 0.3s ease-out;

  &:hover {
    background-color: #c4262c;
  }

  &:focus {
    outline: none;
  }

  &:active {
    background-color: #b21f26;
  }

  &.Modal__SubmitBtn__Confirm {
    width: 152px;
  }

  &.Modal__SubmitBtn__Users {
    width: 165px;
  }

  &.Modal__SubmitBtn__Disabled {
    opacity: 0.5;
  }
}

.Modal_HorizontalLine {
  width: calc(100% + 80px);
  color: #d9dfe8;
  position: relative;
  right: 40px;
  margin: 29px 0px 32px;
}

.Modal__Body {
  padding: 0 !important;
  margin-top: 3px;

  .Modal__InfoPanel {
    margin-top: 24px;
    border-radius: 6px;
    padding: 16px;
    background-color: #eff7ff;
    color: #49505a;
    font: normal normal 400 14px/20px Lato;

    .Modal__InfoPanel__Title {
      display: flex;
      align-items: center;
      font-weight: 700;

      .Modal__InfoPanel__Icon {
        margin-right: 8px;
        height: 14px;
        width: 16px;
        > g > path {
          fill: #118af9;
        }
      }
    }

    .Modal__InfoPanel__Description {
      margin-top: 4px;
    }
  }

  form {
    > * {
      &:last-child {
        margin-bottom: 0 !important;
      }
    }
  }
}

.Modal__AlertIcon {
  height: 38px;
  margin: 1px 0px 21px;
}

.Modal__ManageAlertsIcon {
  height: 46px;
  margin-bottom: 10px;
  path {
    fill: $theme-fill-color-4;
  }
}

.Modal__Loader {
  width: 20px;
  animation: spin 1s infinite linear;
}

@keyframes spin {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(360deg);
  }
}

@media screen and (max-width: 767px) {
  ::-webkit-scrollbar {
    display: none !important;
  }

  .Modal__Title {
    font: normal normal normal 24px/24px Lato;
    padding-bottom: 22px;
  }

  .Modal__AlertTitle {
    font: normal normal bold 24px / 32px Lato;
    padding: 0px 0 27px;
  }

  .Modal__Content {
    padding: 0;
  }

  .Modal__Body {
    padding: 0 20px !important;
    margin-top: 16px;
  }

  .Modal__Header {
    padding-top: 32px !important;
  }

  .Modal__Footer {
    flex-wrap: nowrap;
    justify-content: center;
    padding: 20px !important;

    &.Modal__Footer__Alert {
      top: 0px;
      padding: 30px 20px 6px !important;
    }
  }

  .Modal_HorizontalLine {
    margin: 5px 0;
    width: 100%;
    right: 0px;
  }

  .Modal__Content__Alerts {
    border-radius: 0;
    padding: 0;
    margin: auto;
    position: relative;
    border: none;
    width: 100%;
    background-color: #fff !important;
    left: inherit;
    min-width: initial;

    .Modal__Body {
      padding: 0 16px !important;
      margin-top: 15px;
    }
  }

  .Modal__ManageAlertsIcon {
    display: none;
  }

  .Modal__CloseIcon {
    top: 20px;
    right: 20px;
  }

  .Modal__Content {
    border-radius: 0;
    height: 100%;
  }

  .Modal__CarIcon {
    display: none;
  }

  .Modal__Title {
    font: bold 24px/24px Lato;
  }

  .Modal__ConfirmTitle {
    font: normal normal bold 24px/32px Lato;
  }

  .Modal__CancelBtn {
    &.Modal__CancelBtn__Confirm,
    &.Modal__CancelBtn__Users {
      width: calc(50% - 10px);
    }
  }

  .Modal__SubmitBtn {
    &.Modal__SubmitBtn__Confirm,
    &.Modal__SubmitBtn__Users {
      width: calc(50% - 10px);
    }
  }

  .Modal__Dialog {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .Modal__AlertIcon {
    display: none;
  }
}
