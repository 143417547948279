@import "./../../styles/common.scss";

.Month {
  width: 300px;
  user-select: none;

  @include screen-sm {
    width: 345px;
  }

  &__name {
    @include font(16px, 24px, 700);
    display: flex;
    justify-content: center;
    align-items: center;
    color: $theme-text-color-0;
    height: 70px;
  }

  &__weekDays {
    display: flex;
    justify-content: space-between;
    padding: 0 15px;
  }

  &__dayName {
    @include font(10px, 12px, 700);
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    color: $theme-text-color-1;
    width: 45px;

    &--weekend {
      color: $theme-text-color-19;
    }
  }

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 15px;
  }
}

@media screen and (max-width: 767px) {
  .Month:last-child {
    display: none;
  }
}
