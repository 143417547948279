/* Padding (top + right + bottom + left) */
$padding: 0, 5, 10, 12, 15, 16, 20, 24, 30, 40, 50;

@each $pad in $padding {
  .p-#{$pad} {
    padding: $pad + 0px;
  }
}

/* Padding y (top + bottom) */
$padding-y: 0, 2, 5, 6, 7, 10, 14, 20, 30, 40, 50;

@each $pad in $padding-y {
  .py-#{$pad} {
    padding-top: $pad + 0px;
    padding-bottom: $pad + 0px;
  }
}

/* Padding x (right + left) */
$padding-x: 0, 5, 10, 12, 14, 15, 16, 18, 20, 24, 25, 30, 35, 40, 80;

@each $pad in $padding-x {
  .px-#{$pad} {
    padding-right: $pad + 0px;
    padding-left: $pad + 0px;
  }
}

/* Padding top */
$padding-top: 0, 2, 3, 4, 6, 9, 10, 12, 14, 15, 18, 20, 22, 24, 25, 26, 30, 36,
  40, 50;

@each $pad in $padding-top {
  .pt-#{$pad} {
    padding-top: $pad + 0px;
  }
}

/* Padding right */
$padding-right: 0, 5, 7, 10, 12, 15, 20, 30, 40;

@each $pad in $padding-right {
  .pr-#{$pad} {
    padding-right: $pad + 0px;
  }
}

/* Padding bottom */
$padding-bottom: 0, 3, 8, 10, 12, 15, 20, 24, 25, 26, 28, 30, 32, 40, 80;

@each $pad in $padding-bottom {
  .pb-#{$pad} {
    padding-bottom: $pad + 0px;
  }
}

/* Padding left */
$padding-left: 0, 5, 8, 10, 15, 16, 20, 30, 40;

@each $pad in $padding-left {
  .pl-#{$pad} {
    padding-left: $pad + 0px;
  }
}
