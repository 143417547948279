.modal-backdrop {
  background-color: rgb(19, 50, 112) !important;
}

.modal-backdrop.show {
  opacity: 0.2;
}

.modal {
  backdrop-filter: blur(15px);
}
