.box-shadow-lightest {
  box-shadow: 3px 4px 9px #0000001A;
}

.box-shadow-light {
  box-shadow: 0px 7px 13px #073D7D07;
}

.box-shadow-medium {
  box-shadow: 2px 4px 24px #3F4B8019;
}

.box-shadow-dark {
  box-shadow: 2px 10px 50px #3F4B8021;
}
