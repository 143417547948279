@import "../../styles/common.scss";
@import "../../styles/variables.scss";

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1025;
  width: 216px;
  height: 100vh;
  background-color: $theme-background-color-42;
  transition: width 0.2s linear;

  &:hover {
    .button {
      opacity: 1;
    }
  }

  &.closed {
    width: 72px;

    .button {
      transform: rotate(180deg);
    }

    .container {
      .logoContainer {
        margin: 35px 12px 0;
        .logo {
          display: none;

          &Mobile {
            display: block;
            margin: auto;
            cursor: pointer;
          }
        }
      }

      .itemsContainer {
        margin: 66px 12px 35px;

        &:has(.item:hover) {
          overflow: visible;

          .item:hover {
            width: 208px;

            .text {
              display: block;
            }
          }
        }

        .item {
          margin: 0 auto;
          width: 48px;
          height: 49px;
          border: 4px solid $theme-border-color-16;
          border-radius: 10px;

          .text {
            display: none;
          }
        }
      }
    }
  }

  .button {
    opacity: 0;
    position: absolute;
    top: 72px;
    right: -16px;
    cursor: pointer;
    transition: opacity 0.2s linear;
  }

  .container {
    display: flex;
    flex-direction: column;
    height: 100%;

    .logoContainer {
      margin: 32px 8px 0;
      overflow: hidden;

      .logo {
        display: block;
        box-sizing: content-box;
        margin: 0 39px;
        cursor: pointer;

        &Mobile {
          display: none;
        }
      }
    }

    .logoContainerMobile {
      display: none;
    }

    .itemsContainer {
      overflow: hidden;
      margin: 35px 8px;

      .item {
        margin: 8px auto 0;
        border-radius: 8px;
        height: 41px;
        padding: 8px;
        display: flex;
        align-items: center;
        cursor: pointer;
        user-select: none;

        &:hover {
          background-color: $theme-background-color-43;
        }

        &.active {
          background-color: $theme-background-color-43;

          .icon * {
            fill: $theme-fill-color-0;
          }

          .text {
            color: $theme-text-color-6;
          }
        }

        .icon {
          flex-shrink: 0;
        }

        .text {
          margin-left: 8px;
          color: $theme-text-color-5;
          font-family: Lato;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 16px;
          text-wrap: nowrap;
        }
      }
    }

    .actionsContainer {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
  }
}

@media screen and (max-width: ($md-1 - 1px)) {
  .wrapper {
    top: 64px;
    left: -216px;
    height: calc(100vh - 64px);
    border-top: 1px solid $theme-border-color-0;
    transition: left 0.2s linear;

    &.visible {
      left: 0;
    }

    .button {
      display: none;
    }

    .container {
      .logoContainer {
        display: none;
      }

      .itemsContainer {
        margin-top: 16px;
      }
    }
  }
}

@media screen and (max-width: ($sm - 1px)) {
  .wrapper {
    top: 0;
    height: 100vh;
    border-top: 0;

    .container {
      .logoContainerMobile {
        display: flex;
        align-items: center;
        height: 65px;
        border-bottom: 1px solid $theme-border-color-0;

        .closeButton {
          margin-left: 16px;
          cursor: pointer;
        }

        .logo {
          margin-left: 24px;
          width: 112px;
          height: 32px;
          cursor: pointer;
        }
      }
    }
  }
}
