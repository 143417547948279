@import "../../../styles/common.scss";
@import "../../../styles/variables.scss";

.wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  user-select: none;

  &:hover {
    .checkbox {
      border: 2px solid $theme-border-color-12;
    }

    .title {
      color: $theme-text-color-13;
    }
  }

  &.selected {
    .checkbox {
      border: 2px solid $theme-border-color-12;
      background: url("../../../assets/icons/checkbox.svg") no-repeat center;
    }
  }

  .checkbox {
    width: 20px;
    height: 20px;
    border: 1px solid $theme-border-color-2;
    border-radius: 4px;
  }

  .title {
    margin-top: 1px;
    color: $theme-text-color-1;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
  }
}

@media screen and (max-width: ($md-1 - 1px)) {
  .wrapper {
    &.selected {
      .checkbox {
        border: 2px solid $theme-border-color-12 !important;
      }
    }

    .checkbox {
      border: 1px solid $theme-border-color-2 !important;
    }

    .title {
      color: $theme-text-color-1 !important;
    }
  }
}
