@import "../../../styles/common.scss";
@import "./index.scss";

// DangerLight Button
.Button {
  @include button-general();
  @include button-size();
  @include flex-center();

  &.Button__danger--light {
    color: $theme-text-color-13;
    background-color: $theme-background-color-31;

    @include button-actions(
      $theme-background-color-30,
      $theme-background-color-29,
      $theme-background-color-28
    );
  }

  @include button-loading(
    $theme-border-color-12,
    fade($theme-background-color-28, 30%)
  );
}
