.vehicleAvatar {
  position: relative;
  width: 43px;
  height: 43px;
  background-position: center left 6px;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: white;
  border-radius: 50%;
  border: 1px solid #e9ebf2;
}

.engineIcon {
  @media screen and (max-width: 767px) {
    height: 30px;
    width: 30px;
    right: -5px;
    top: -5px;
  }

  img {
    width: 21px;
  }
}
