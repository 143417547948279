@import "../../../../../styles/common.scss";

.content {
  background-color: $theme-background-color-10 !important;
  padding: 40px;
  width: fit-content;
  width: -moz-fit-content;
  margin: auto;
  left: -40%;
}

.container {
  width: 828px;
  padding: 20px 30px 30px;
  background-color: $theme-background-color-13;
  border-radius: 7px;
  box-shadow: 0px 7px 13px #073d7d07;
}

.header {
  text-align: center;
  display: flex;
  justify-content: center;

  .manageIcon {
    height: 46px;
    margin-bottom: 10px;
    path {
      fill: $theme-fill-color-4;
    }
  }

  .title {
    font: normal normal normal 30px/29px Lato;
    color: #191919;
    padding-bottom: 12px;
  }
}

.closeIcon {
  width: 14px;
  position: absolute;
  right: 40px;
  top: 30px;
  &:hover {
    cursor: pointer;
    path {
      fill: $theme-fill-color-12;
    }
  }
}

.headerDelimeter {
  width: calc(100% + 80px);
  color: #d9dfe8;
  position: relative;
  right: 40px;
  margin-bottom: 25px;
}

.body {
  padding: 0 !important;
  margin-top: 10px;
}

.contentHeader {
  @include flex(space-between, center, row, nowrap);
  margin: 0 0 30px;

  .counterLabel {
    font: normal normal bold 18px/24px Lato;
    color: $theme-text-color-0;

    span {
      font: normal normal normal 18px/24px Lato;
      color: $theme-text-color-4;
      margin-left: 5px;
    }
  }

  .addButton {
    padding-right: 0;
    font: normal normal bold 13px/24px Lato;
    width: 91px;
    text-align: right;
    border: none;
    color: $theme-text-color-13;
    background-image: url(../../../../../assets/icons/add-plus-red.svg);
    background-color: transparent;
    background-position: 0px 5px;
    background-repeat: no-repeat;

    &:focus,
    &:active {
      outline: none;
    }
  }
}

.tableHeader {
  display: grid;
  grid-template-columns: 296px 168px 208px 15px;
  grid-column-gap: 30px;
  align-items: center;
  margin-bottom: 20px;

  .headerCell {
    font: normal normal normal 13px/13px Lato;
    color: $theme-text-color-4;
  }
}

.serviceRow {
  @include flex(space-between, center, row, nowrap);

  .serviceName {
    width: 296px;
  }

  .counterInput {
    width: 143px !important;
  }

  .serviceDate {
    width: 208px;
  }
}

.input {
  background: $theme-background-color-13;
  border: 1px solid $theme-border-color-4;
  border-radius: 5px;
  height: 45px;
  font: normal normal bold 14px/36px Lato;
  color: $theme-text-color-0;
  padding: 14px 20px;
  text-overflow: ellipsis;

  &:hover,
  &:focus {
    cursor: pointer;
    border-color: $theme-border-color-14;
    box-shadow: 0 0 0 1px $theme-shadow-color-0;
  }

  &::placeholder {
    font-weight: normal;
    color: $theme-text-color-4;
  }
}

.dropdown {
  margin-left: -15px;
}

.delimiter {
  width: calc(100% + 60px);
  height: 1px;
  background-color: $theme-border-color-4;
  margin: 11px 0 11px auto;
  position: relative;
  right: 30px;
}

.serviceRowMobile {
  display: none;
}

.inputContainer {
  display: flex;
  align-items: center;
}

.controls {
  display: flex;

  .controlsButton {
    font: normal normal bold 14px/24px Lato;
    width: 99px;
    height: 35px;
    border: none;
    background-position: 20px;
    background-repeat: no-repeat;
    border-radius: 5px;
    padding-left: 15px;

    &:focus,
    &:active {
      outline: none;
    }

    &:first-child {
      color: #29c07b;
      background-image: url(../../../../../assets/icons/check.svg);
      background-color: $theme-background-color-25;
      margin-right: 20px;
      margin-left: 30px;

      &:hover {
        background-color: #c6f9e1;
      }
    }

    &:last-child {
      color: #e1950d;
      background-image: url(../../../../../assets/icons/close-bold.svg);
      background-color: #fff3dc;

      &:hover {
        background-color: #fce9c7;
      }
    }
  }
}

.arrowIcon {
  height: 18px;
  width: 26px;
  position: relative;
  top: -2px;
  margin-right: 20px;

  &:hover {
    cursor: pointer;

    path {
      fill: $theme-fill-color-12;
    }
  }
}

.contentInputs {
  display: flex;
  flex-direction: column;
  padding: 30px 20px 40px;

  .inputRow {
    margin-bottom: 25px;
  }

  .label {
    font: normal normal 600 14px Lato;
    margin-bottom: 10px;
    color: $theme-text-color-2;
  }

  .editCounterInput {
    width: calc(100% - 25px) !important;
  }

  .deleteButton {
    max-width: 100%;
    height: 40px;
    border: none;
    border-radius: 5px;
    font: normal normal bold 14px/29px Lato;
    color: $theme-text-color-1;
    background-color: $theme-background-color-7;
    margin-top: 5px;
    @include flex-center;

    svg {
      width: 15px;
      height: 18px;
      margin-right: 7px;
    }

    &:hover {
      background-color: $theme-background-color-4;
    }
    &:focus {
      outline: none;
    }
    &:active {
      background-color: $theme-background-color-5;
      color: $theme-text-color-19;
      outline: none;

      svg path {
        fill: $theme-fill-color-12;
      }
    }
  }
}
.footer {
  border-top: none !important;
  padding: 24px 0 0 !important;
}

.editModalFooter {
  border-top: none !important;
  padding: 35px 0 0 !important;
}

.footerButton {
  width: 152px;
  height: 40px;
  margin-right: 20px !important;
  font: normal normal bold 14px/18px Lato;
  background: none;
  border: none;
  border-radius: 5px;
  outline: none;
}

.cancelBtn {
  color: $theme-text-color-1;

  &:hover {
    background-color: $theme-background-color-7;
  }

  &:focus {
    outline: none;
  }

  &:active {
    background-color: $theme-background-color-4;
  }
}

.submitBtn {
  background: $theme-background-color-28;
  color: $theme-text-color-6;
  margin: 0 !important;

  &:hover {
    background-color: $theme-background-color-27;
  }

  &:focus {
    outline: none;
  }

  &:active {
    background-color: $theme-background-color-26;
  }
}

@media screen and (max-width: 1023px) {
  .content {
    left: -27%;
  }

  .container {
    width: 688px;
  }

  .serviceRow {
    .serviceName {
      width: 206px;
    }

    .serviceDate {
      width: 188px;
    }
  }

  .tableHeader {
    grid-template-columns: 206px 168px 188px 15px;
    grid-column-gap: 20px;
  }
}

@media screen and (max-width: 767px) {
  .content {
    padding: 20px 0;
    box-shadow: none;
    background-color: $theme-background-color-13 !important;
    left: 0;
  }

  .header {
    margin: 0 30px !important;

    .title {
      font: normal normal bold 24px/32px Lato;
      padding: 12px 0 20px;
    }
  }

  .closeIcon {
    top: 20px;
    right: 20px;
  }

  .headerDelimeter {
    margin: 5px 0;
    width: calc(100% + 40px);
    right: 20px;
  }

  .container {
    width: 100%;
    padding: 0;
    box-shadow: none;
  }

  .contentHeader {
    margin: 0 20px;
  }

  .tableHeader,
  .serviceRow {
    display: none;
  }

  .serviceRowMobile {
    @include flex(space-between, center, row, nowrap);
    padding: 14px 20px;
    color: $theme-text-color-0;

    &:hover {
      cursor: pointer;
      background: $theme-background-color-20;

      svg path {
        fill: $theme-fill-color-12;
      }
    }

    .name {
      font: normal normal bold 14px/36px Lato;

      span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    svg {
      width: 10px;
      height: 10px;
      cursor: pointer;
      margin-left: 10px;
      transform: matrix(0, -1, 1, 0, 0, 0);
    }
  }

  .delimiter {
    margin: 0 auto;
    width: 100%;
    right: 0;
  }

  .hidden {
    display: none;
  }

  .footer {
    flex-wrap: nowrap;
    justify-content: center;
    padding-top: 15px !important;
  }

  .editModalFooter {
    flex-wrap: nowrap;
    justify-content: center;
    padding-top: 0px !important;
    width: calc(100% + 40px);
    margin-left: -20px;
    height: 75px;
    box-shadow: 0px 4px 24px 0px #3f4b801a;
    clip-path: inset(-30px 0px 0px 0px);
  }

  .footerButton {
    width: 130px;
  }

  .inputContainer {
    flex-wrap: wrap;
    margin: 30px auto 20px;
    width: min-content;
  }

  .controls {
    margin-top: 20px;

    .controlsButton {
      width: 130px;
      background-position: 35px;

      &:first-child {
        margin-left: 0;
      }
    }
  }
}

.mobilePicker {
  display: block !important;
}
