@import "../../styles/common.scss";

.label {
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  color: $theme-text-color-2;

  &_disabled {
    color: $theme-text-color-4;
  }
}
