@import "../../../../styles/colors.scss";

.SpinnerWrapper {
  min-height: 567px;
}

.ReadinessMonitors {
  &__summary {
    height: 80px;
    border-radius: 5px;

    padding: 14px 0 0 66px;

    background-size: 35px 35px;
    background-repeat: no-repeat;
    background-position: 20px 23px;

    &--completed {
      background-color: #e5fff3;
      background-image: url(../../../../assets/icons/monitor-completed.svg);
    }

    &--notCompleted {
      background-color: #fff3dc;
      background-image: url(../../../../assets/icons/monitor-not-completed.svg);
    }
  }

  &__no-data {
    height: calc(100% - 24px) !important;

    .Container {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;

      .icon {
        margin: 65px auto 20px;
        height: 160px;
        width: 160px;
      }

      > :last-child {
        display: table-cell;
        margin: 0 auto 55px;
        color: $theme-text-color-4;
        text-align: center;
      }
    }
  }

  &__summaryLabel {
    color: $theme-text-color-2;
    font-weight: 500;
    font-size: 13px;
  }

  &__summaryCounter {
    color: #191919;
    font-weight: 900;
    font-size: 30px;

    @media (min-width: 992px) and (max-width: 1300px) {
      margin-top: 0 !important;
    }
  }

  &__tableHeader {
    flex-grow: 1;

    color: $theme-text-color-2;
    font-size: 13px;

    &--small {
      width: 70px;
      flex-grow: 0;

      @media (min-width: 992px) and (max-width: 1200px) {
        min-width: 38px;
        width: 17%;
        &:last-child {
          margin-left: 10px;
        }
      }

      @media screen and (max-width: 991px) {
        width: 85px;
        &:last-child {
          margin-left: calc((100vw - 320px) / 2 - 116px);
        }
      }
    }
  }

  &__rowsContainer {
    overflow-y: auto;
    max-height: 332px;
    width: calc(100% + 18px);
    padding-right: 11px;

    @media (min-width: 992px) and (max-width: 1200px) {
      width: calc(100% + 15px);
      max-height: 222px;
    }
  }

  &__monitorRow {
    border-bottom: 1px solid $theme-border-color-4;

    @media (min-width: 992px) and (max-width: 1200px) {
      width: 100%;
      padding-right: 10px;
    }
  }

  &__monitorLabel {
    color: #191919;
    font-size: 16px;
    font-weight: 700;
    flex-grow: 1;
  }

  &__monitorIcon {
    width: 70px;
    height: 30px;
    background-position: 20px center;
    background-repeat: no-repeat;
    background-size: 25px 25px;
    flex-grow: 0;

    @media (min-width: 992px) and (max-width: 1200px) {
      min-width: 38px !important;
      width: 15% !important;
      background-position: 0;
      &:last-child {
        margin-left: 10px !important;
      }
    }

    &--completed {
      background-image: url(../../../../assets/icons/monitor-completed-bordered.svg);
    }

    &--notCompleted {
      background-image: url(../../../../assets/icons/monitor-not-completed-bordered.svg);
    }
  }
}

@media screen and (max-width: 1023px) {
  .ReadinessMonitors {
    &__no-data {
      min-height: 160px;
    }

    &__summary {
      &--completed {
        margin-right: 40px !important;
      }
    }

    &__rowsContainer {
      width: calc(100% + 25px);
    }

    &__monitorIcon {
      &:last-child {
        width: 100px;
        margin-left: calc((100vw - 320px) / 2 - 100px);
      }
    }
  }

  .MobileHidden {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .ReadinessMonitors {
    &__summary {
      margin-right: 0 !important;
      width: 100% !important;

      &:last-child {
        margin-top: 24px;
      }
    }

    &__summaryCounter {
      margin-top: 0 !important;
    }

    &__rowsContainer {
      width: calc(100% + 15px);
    }

    &__tableHeader {
      max-width: calc(100% - 141px);

      &--small {
        min-width: 63px;
        width: 17%;

        &:last-child {
          margin-left: 10px;
        }
      }
    }

    &__monitorRow {
      width: 100%;
    }

    &__monitorLabel {
      white-space: pre-wrap;
      word-break: break-word;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      max-width: calc(100% - 136px);
      margin-right: 10px;
    }

    &__monitorIcon {
      min-width: 63px !important;
      width: 17%;
      background-position: 0 center;

      &:last-child {
        min-width: 63px !important;
        width: 17%;
        margin-left: 10px !important;
      }
    }

    .summaryMobileWrapper {
      flex-direction: column;
    }
  }
}

@media screen and (max-width: 454px) {
  .ReadinessMonitors {
    &__monitorRow {
      padding-right: 10px;
    }
  }
}
