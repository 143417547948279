@import "../../../../styles/common.scss";

.PidGraphs__Wrapper {
  height: 500px;
}

.PidGraphs__Select {
  height: 45px;
  border-radius: 5px;
  border: 1px solid $theme-border-color-4;
}

.PidGraphs__ControllsWrapper {
  display: flex;
}

.SpinnerWrapper {
  min-height: 200px;
}

.noData {
  display: flex;
  flex-direction: column;
  margin: 45px auto 55px;
  color: $theme-text-color-4;
  text-align: center;

  .icon {
    margin: 20px auto;
    height: 160px;
    width: 160px;
  }
}

@media screen and (max-width: 1023px) {
  .SelectWrapper {
    width: 40% !important;
    margin-right: 0 !important;
  }

  .DatePickerWrapper {
    width: 60% !important;
  }

  .PidGraphs__ControllsWrapper {
    margin-top: 0 !important;
  }
}

@media screen and (max-width: 767px) {
  .HeaderLabel {
    width: 100% !important;
  }

  .PidGraphs__ControllsWrapper {
    flex-direction: column;
  }

  .SelectWrapper {
    width: 100% !important;
    margin-right: 0 !important;
    margin-bottom: 20px;
  }

  .DatePickerWrapper {
    width: 100% !important;
    margin-left: 0 !important;
  }
}
