$theme-colors: (
  0: #191919,
  1: #49505a,
  3: #707d8f,
  5: #aeb3ca,
  6: #95a1b2,
  7: #d2d9e8,
  8: #d9dfe8,
  9: #c8d0da,
  10: #e7e7e7,
  11: #ebeff4,
  12: #eaebf9,
  13: #ededf6,
  15: #f8f9fc,
  17: #f7f7f9,
  18: #ffffff,
  19: #0462b8,
  20: #0879e1,
  21: #108af9,
  22: #9cd1fe,
  23: #cae4f9,
  24: #dbeefe,
  25: #f2f4f8,
  26: #f5f8fe,
  27: #0e7c4a,
  28: #14a462,
  29: #29c07b,
  30: #cffae6,
  31: #b21f26,
  32: #c4262c,
  33: #d7282f,
  34: #f1a3ad,
  35: #f6cacf,
  36: #ffd7dc,
  37: #e1950d,
  38: #f8b131,
  39: #fff3dc,
  40: #3e5678,
  41: #575fcf,
  42: #9949aa,
  43: #f3e9f5,
  44: #ebe3f8,
  45: #eceff1,
  47: #eff1f7,
  48: #118af9,
  49: #b07e23,
  50: #ff5151,
  51: #ffd9d9,
  52: #eff7ff,
);

/* theme text colors */
$theme-text-color-0: map-get($theme-colors, 0);
$theme-text-color-1: map-get($theme-colors, 1);
$theme-text-color-2: map-get($theme-colors, 3);
$theme-text-color-4: map-get($theme-colors, 6);
$theme-text-color-5: map-get($theme-colors, 9);
$theme-text-color-6: map-get($theme-colors, 18);
$theme-text-color-7: map-get($theme-colors, 19);
$theme-text-color-8: map-get($theme-colors, 20);
// $theme-text-color-9: map-get($theme-colors, 21);
$theme-text-color-10: map-get($theme-colors, 27);
$theme-text-color-11: map-get($theme-colors, 28);
$theme-text-color-12: map-get($theme-colors, 29);
$theme-text-color-13: map-get($theme-colors, 33);
$theme-text-color-14: map-get($theme-colors, 37);
$theme-text-color-15: map-get($theme-colors, 38);
$theme-text-color-16: map-get($theme-colors, 40);
$theme-text-color-17: map-get($theme-colors, 41);
$theme-text-color-18: map-get($theme-colors, 42);
$theme-text-color-19: map-get($theme-colors, 48);
$theme-text-color-20: map-get($theme-colors, 49);
$theme-text-color-21: map-get($theme-colors, 50);
$theme-text-color-22: map-get($theme-colors, 11);

/* theme background colors */
$theme-background-color-0: map-get($theme-colors, 3);
$theme-background-color-2: map-get($theme-colors, 5);
$theme-background-color-3: map-get($theme-colors, 7);
$theme-background-color-4: map-get($theme-colors, 8);
$theme-background-color-5: map-get($theme-colors, 9);
$theme-background-color-6: map-get($theme-colors, 10);
$theme-background-color-7: map-get($theme-colors, 11);
$theme-background-color-8: map-get($theme-colors, 12);
$theme-background-color-9: map-get($theme-colors, 13);
$theme-background-color-10: map-get($theme-colors, 15);
$theme-background-color-12: map-get($theme-colors, 17);
$theme-background-color-13: map-get($theme-colors, 18);
$theme-background-color-14: map-get($theme-colors, 19);
$theme-background-color-15: map-get($theme-colors, 20);
$theme-background-color-16: map-get($theme-colors, 21);
$theme-background-color-17: map-get($theme-colors, 22);
$theme-background-color-18: map-get($theme-colors, 23);
$theme-background-color-19: map-get($theme-colors, 24);
$theme-background-color-20: map-get($theme-colors, 25);
$theme-background-color-21: map-get($theme-colors, 26);
$theme-background-color-22: map-get($theme-colors, 27);
$theme-background-color-23: map-get($theme-colors, 28);
$theme-background-color-24: map-get($theme-colors, 29);
$theme-background-color-25: map-get($theme-colors, 30);
$theme-background-color-26: map-get($theme-colors, 31);
$theme-background-color-27: map-get($theme-colors, 32);
$theme-background-color-28: map-get($theme-colors, 33);
$theme-background-color-29: map-get($theme-colors, 34);
$theme-background-color-30: map-get($theme-colors, 35);
$theme-background-color-31: map-get($theme-colors, 36);
$theme-background-color-32: map-get($theme-colors, 38);
$theme-background-color-38: map-get($theme-colors, 39);
$theme-background-color-33: map-get($theme-colors, 40);
$theme-background-color-35: map-get($theme-colors, 42);
$theme-background-color-36: map-get($theme-colors, 43);
$theme-background-color-37: map-get($theme-colors, 44);
$theme-background-color-39: map-get($theme-colors, 6);
$theme-background-color-40: map-get($theme-colors, 48);
$theme-background-color-41: map-get($theme-colors, 51);
$theme-background-color-42: map-get($theme-colors, 0);
$theme-background-color-43: map-get($theme-colors, 1);
$theme-background-color-44: map-get($theme-colors, 52);

/* theme border colors */
$theme-border-color-0: map-get($theme-colors, 1);
$theme-border-color-2: map-get($theme-colors, 8);
$theme-border-color-4: map-get($theme-colors, 11);
$theme-border-color-5: map-get($theme-colors, 13);
$theme-border-color-7: map-get($theme-colors, 15);
$theme-border-color-8: map-get($theme-colors, 18);
$theme-border-color-10: map-get($theme-colors, 25);
$theme-border-color-11: map-get($theme-colors, 29);
$theme-border-color-12: map-get($theme-colors, 33);
$theme-border-color-13: map-get($theme-colors, 38);
$theme-border-color-14: map-get($theme-colors, 48);
$theme-border-color-15: map-get($theme-colors, 50);
$theme-border-color-16: map-get($theme-colors, 0);
$theme-border-color-17: map-get($theme-colors, 9);

/* theme shadow colors */
$theme-shadow-color-0: map-get($theme-colors, 21);
$theme-shadow-color-1: map-get($theme-colors, 38);

/* theme icon fill colors */
$theme-fill-color-0: map-get($theme-colors, 18);
$theme-fill-color-1: map-get($theme-colors, 19);
$theme-fill-color-2: map-get($theme-colors, 20);
$theme-fill-color-3: map-get($theme-colors, 21);
$theme-fill-color-4: map-get($theme-colors, 33);
$theme-fill-color-5: map-get($theme-colors, 29);
$theme-fill-color-7: map-get($theme-colors, 8);
$theme-fill-color-8: map-get($theme-colors, 38);
$theme-fill-color-9: map-get($theme-colors, 3);
$theme-fill-color-10: map-get($theme-colors, 37);
$theme-fill-color-11: map-get($theme-colors, 6);
$theme-fill-color-12: map-get($theme-colors, 48);
$theme-fill-color-13: map-get($theme-colors, 49);
$theme-fill-color-14: map-get($theme-colors, 50);
$theme-fill-color-15: map-get($theme-colors, 1);

/* optional colors */
$twitter-hover-color: #38a1f3;
$facebook-hover-color: #4267b2;
