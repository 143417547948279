@import "../../../styles/common.scss";
@import "./index.scss";

// DangerLarge Button
.Button {
  @include button-general();
  @include button-size(266px);

  &.Button__danger--large {
    color: $theme-text-color-1;
    background-color: $theme-background-color-20;

    &.Button--isLoad {
      background-color: $theme-background-color-20;
    }

    @include button-actions(
      $theme-background-color-28,
      $theme-background-color-26
    );

    &:disabled {
      background-color: $theme-background-color-7;
      opacity: 1;
      color: $theme-text-color-4;
    }
  }

  @include button-loading(
    $theme-border-color-12,
    fade($theme-background-color-28, 30%)
  );
}
