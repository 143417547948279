@import "../../styles/common.scss";

.wrapper {
  position: relative;
  margin: 0 auto;
  max-width: 1390px;
  padding: 24px 40px;

  .title {
    color: $theme-text-color-0;
    font-family: Lato;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
  }

  .section {
    margin-top: 24px;

    .summary {
      display: flex;
      justify-content: space-between;
      gap: 24px;

      .card {
        flex-grow: 1;
        display: flex;
        align-items: center;
        gap: 16px;
        box-shadow: 0px 7px 13px 0px #073d7d08;
        border-radius: 8px;
        padding: 24px;
        background-color: $theme-background-color-13;

        .info {
          display: flex;
          flex-direction: column;
          gap: 8px;

          .title {
            color: $theme-text-color-2;
            font-family: Lato;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
          }

          .number {
            color: $theme-text-color-0;
            font-family: Lato;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 32px;
          }
        }
      }
    }

    .table {
      position: relative;
      background-color: $theme-background-color-13;
      box-shadow: 0px 7px 13px 0px #073d7d08;
      border-radius: 8px;

      .spinner {
        min-height: 450px;
      }

      .noData {
        display: flex;
        flex-direction: column;
        align-items: center;

        .icon {
          margin: 64px auto 20px;
          height: 160px;
          width: 160px;
        }

        .text {
          margin-bottom: 64px;
          color: $theme-text-color-4;
          text-align: center;
          font-family: Lato;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 20px;
        }
      }

      .header {
        display: flex;
        align-items: center;
        border-bottom: 1px solid $theme-border-color-4;

        .cell {
          width: 27%;
          min-height: 64px;
          padding: 10px 24px;
          display: flex;
          align-items: center;
          color: $theme-text-color-2;
          font-family: Lato;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          cursor: pointer;
          user-select: none;
          transition: color 0.2s;

          &.hidden {
            display: none;
          }

          &.shop {
            width: 400px;
            min-width: 30%;
          }

          &.customer {
            min-width: 390px;
          }

          &.total {
            min-width: 148px;
          }

          &.installButton {
            padding-left: 0;
            width: 200px;
            min-width: 13%;
          }

          &:has(.visible) {
            color: $theme-text-color-1;
          }

          .icon {
            margin-left: 7px;
            margin-bottom: 1px;
            min-width: 8px;
            opacity: 0.6;
            transition: opacity 0.2s, fill 0.2s;

            path {
              fill: $theme-fill-color-11;
              transition: fill 0.2s;
            }

            &.visible {
              opacity: 1;

              path {
                fill: $theme-fill-color-15;
              }
            }

            &.asc {
              transform: rotate(180deg);
            }
          }

          &:hover {
            color: $theme-text-color-1;

            .icon {
              opacity: 1;

              path {
                fill: $theme-fill-color-15;
              }
            }
          }
        }
      }

      .row {
        border-bottom: 1px solid $theme-border-color-4;

        &:last-child {
          border-bottom: none;
        }

        .desktop {
          display: flex;
          align-items: center;

          .cell {
            width: 27%;
            min-height: 64px;
            padding: 10px 24px;
            display: flex;
            align-items: center;
            color: $theme-text-color-1;
            font-family: Lato;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            user-select: none;

            &.hidden {
              display: none;
            }

            &.customer {
              min-width: 390px;
              cursor: pointer;

              .icon {
                position: relative;
                margin-right: 16px;
                width: 44px;
                height: 44px;
                flex-shrink: 0;
                background-position: center left 3px;
                background-size: 180%;
                background-repeat: no-repeat;
                background-color: $theme-background-color-13;
                border-radius: 50%;
                border: 1px solid $theme-border-color-2;

                .check {
                  width: 32px;
                  height: 32px;
                  right: -10px;
                  top: -6px;
                }
              }

              .name {
                color: $theme-text-color-0;
                font-family: Lato;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 16px;

                .customer {
                  display: block;
                  margin-top: 4px;
                  color: $theme-text-color-2;
                  font-family: Lato;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 16px;
                }
              }
            }

            &.shop {
              width: 400px;
              min-width: 30%;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              overflow: hidden;
              font-family: Lato;
              font-style: normal;
              line-height: 16px;

              .name {
                width: 100%;
                color: $theme-text-color-0;
                font-size: 14px;
                font-weight: 700;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }

              .address {
                width: 100%;
                margin-top: 4px;
                color: $theme-text-color-2;
                font-size: 12px;
                font-weight: 400;
                overflow: hidden;
                text-overflow: ellipsis;
                text-wrap: nowrap;
              }
            }

            &.total {
              min-width: 148px;
            }

            &.available {
              min-width: 178px;
            }

            &.installButton {
              padding-left: 0;
              width: 200px;
              min-width: 13%;
            }

            .arrow,
            .cellMobile {
              display: none;
            }
          }

          .arrowButton {
            display: none;
          }
        }

        .mobile {
          display: none;
        }
      }
    }
  }

  .buttonInstallDevice {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 0 24px 0 0;
    height: 44px;
    flex-shrink: 0;
    border-radius: 6px;
    padding: 0 16px;
    background-color: $theme-background-color-7;
    user-select: none;
    cursor: pointer;
    transition: background-color 0.2s ease-out;

    &:hover {
      background-color: $theme-background-color-4;
    }
    &:active {
      background-color: $theme-background-color-5;
    }

    .icon {
      width: 20px;
      height: 24px;
    }

    .title {
      color: $theme-text-color-1;
      font-family: Lato;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
    }
  }
}

@media screen and (max-width: ($lg-1 - 1px)) {
  .wrapper {
    padding: 24px;

    .section {
      .table {
        .header {
          .cell {
            &.shop {
              width: 250px;
              min-width: 26.6%;
            }

            &.customer {
              min-width: 390px;
            }

            &.total {
              min-width: 0;
            }

            &.available {
              min-width: 0;
            }

            &.installButton {
              min-width: 192px;
            }
          }
        }

        .row {
          .desktop {
            .cell {
              &.shop {
                width: 250px;
                min-width: 26.6%;
              }

              &.customer {
                min-width: 390px;
              }

              &.total {
                min-width: 0;
              }

              &.available {
                min-width: 0;
              }

              &.installButton {
                min-width: 192px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  .wrapper {
    padding: 24px;

    .section {
      .table {
        .header {
          .cell {
            &:not(.customer, .shop, .installButton) {
              padding: 10px 16px;
            }

            &.customer {
              min-width: 320px;
            }
          }
        }

        .row {
          .desktop {
            .cell {
              &:not(.customer, .shop, .installButton) {
                padding: 10px 16px;
              }

              &.customer {
                min-width: 320px;
              }

              &.installButton {
                width: 192px;
                min-width: 192px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: ($lg - 1px)) {
  .wrapper {
    .section {
      .table {
        .header {
          .cell {
            &:not(.customer, .shop, .installButton) {
              padding: 10px;
              min-width: 80px;
            }

            &.shop {
              width: 225px;
              min-width: 29.6%;
            }

            &.customer {
              min-width: 290px;
            }

            &.installButton {
              width: 135px;
              min-width: 135px;
            }
          }
        }

        .row {
          .desktop {
            .cell {
              &:not(.customer, .shop, .installButton) {
                padding: 10px;
              }

              &.shop {
                width: 225px;
                min-width: 29.6%;
              }

              &.customer {
                min-width: 290px;
              }

              &.installButton {
                width: 135px;
                min-width: 135px;
                justify-content: center;
              }
            }
          }
        }
      }
    }

    .buttonInstallDevice {
      margin: 0;
    }
  }
}

@media screen and (max-width: ($md-1 - 1px)) {
  .wrapper {
    padding: 24px 16px;

    .section {
      .summary {
        .card {
          padding: 24px 10px 24px 24px;
        }
      }

      .table {
        .header {
          .cell {
            &:not(.customer, .shop, .installButton) {
              min-width: 78px;
            }

            &.customer,
            &.shop {
              width: 210px;
              min-width: 28.6%;
              padding: 10px 0 10px 24px;
            }

            &.customer {
              min-width: 270px;
            }
          }
        }

        .row {
          .desktop {
            .cell {
              &:not(.customer, .shop, .installButton) {
                min-width: 78px;
              }

              &.customer,
              &.shop {
                width: 210px;
                min-width: 28.6%;
                padding: 10px 0 10px 24px;
              }

              &.customer {
                min-width: 270px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: ($sm - 1px)) {
  .wrapper {
    .section {
      .summary {
        flex-direction: column;

        .card {
          padding: 24px;
        }
      }

      .table {
        .header {
          display: none;
        }

        .row {
          position: relative;

          .desktop {
            position: relative;
            padding-right: 68px !important;

            &:has(.cell:not(.customer, .hidden, .shop)) {
              .button {
                display: flex;
              }
            }

            .cell {
              display: none;

              &.customer,
              &.shop {
                display: flex;
                order: -1 !important;
                width: initial;
                min-width: initial;
                max-width: initial;
              }
            }

            .arrowButton {
              display: flex;
              position: absolute;
              right: 0;
              padding: 30px 29px;
              align-items: center;
              grid-gap: 8px;
              gap: 8px;
              cursor: pointer;
              -webkit-user-select: none;
              user-select: none;
              transition: all 0.3s;

              &:hover {
                .arrow path {
                  fill: $theme-fill-color-12;
                }
              }

              .arrow {
                width: 9px;
                height: 6px;
              }

              &.expanded {
                transform: rotate(180deg);

                .arrow path {
                  fill: $theme-fill-color-12;
                }
              }
            }
          }

          &.expanded {
            border-radius: 8px;
            box-shadow: 2px 10px 50px 0px #3f4b8021;
            border-bottom: none;
            margin: 0;
            padding: 0;

            .mobile {
              display: flex;
              flex-direction: column;
              transition: transform 0.3s ease;

              .cell {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-basis: 100%;
                min-height: 56px;
                padding: 10px 24px;
                user-select: none;
                font-family: Lato;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;

                &.hidden {
                  display: none;
                }

                &.installButton {
                  padding-bottom: 20px;
                }

                .shop,
                .customer {
                  max-width: 47vw;
                  text-align: end;
                }

                .parameter {
                  color: $theme-text-color-2;
                  flex-shrink: 0;
                }

                .value {
                  display: flex;
                  align-content: center;
                  color: $theme-text-color-1;
                }
              }
            }
          }
        }
      }
    }

    .buttonInstallDevice {
      width: 100%;
      justify-content: center;
    }
  }
}
