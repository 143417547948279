@import "../../../../styles/colors.scss";

.CustomerBanner {
  display: flex;
  align-items: center;
  background-color: white;
  box-shadow: 0px 7px 13px #073d7d07;
  border-radius: 8px;
  padding: 0 24px;

  &__avatar {
    width: 60px;
    height: 60px;
    background-image: url(../../../../assets/icons/green-avatar.svg);
    background-size: contain;
    background-repeat: no-repeat;
    margin: 24px 0 24px 16px;
  }

  &__name {
    color: $theme-text-color-0;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    margin-right: 24px;
  }

  &__arrowBack {
    align-self: flex-start;
    margin-top: 30px;

    width: 26px;
    height: 18px;
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
    path {
      fill: $theme-fill-color-11;
    }
    &:hover {
      path {
        fill: $theme-fill-color-12;
      }
    }
  }

  &__IconPack {
    margin-left: 20px;
  }

  &__IconWrapper {
    height: 36px;
    width: 36px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: $theme-background-color-19;
      border-radius: 50%;
      cursor: pointer;

      svg {
        path {
          fill: $theme-fill-color-12;
        }
      }
    }

    svg {
      path {
        fill: $theme-fill-color-11;
      }
    }
  }

  .disconnectButton {
    margin-left: auto;
    padding: 0 20px;
    display: flex;
    align-items: center;
    min-width: 200px;
    height: 40px;
    border-radius: 5px;
    background-color: $theme-background-color-20;
    color: $theme-text-color-1;
    font: bold 14px Lato;
    cursor: pointer;

    &:hover {
      background-color: $theme-background-color-28;
      color: $theme-text-color-6;

      svg {
        path {
          fill: $theme-fill-color-0;
        }
      }
    }

    &:active {
      background-color: $theme-background-color-26;
      color: $theme-text-color-6;

      svg {
        path {
          fill: $theme-fill-color-0;
        }
      }
    }

    .icon {
      margin-right: 7px;
      width: 18px;
    }
  }

  .customerDropdown {
    display: none;
  }

  @mixin CustomerListItem__avatar {
    position: relative;
    min-width: 60px;

    @media (max-width: 767px) {
      background-size: cover;
      margin: 24px auto 15px !important;
    }
  }

  .CustomerListItem__avatar {
    @include CustomerListItem__avatar;
    background-image: url(../../../../assets/icons/green-avatar.svg);
  }

  .CustomerListItem__avatarRed {
    @include CustomerListItem__avatar;
    background-image: url(../../../../assets/icons/red-avatar.svg);
  }

  .CustomerListItem__redAvatar {
    @include CustomerListItem__avatar;
    background-image: url(../../../../assets/icons/business-profile-red.svg);
  }

  .CustomerListItem__greenAvatar {
    @include CustomerListItem__avatar;
    background-image: url(../../../../assets/icons/business-profile-green.svg);
  }
}

@media screen and (max-width: 1023px) {
  .CustomerBanner {
    padding: 0 20px;

    &__avatar {
      margin: 20px 0 20px 20px;
    }

    &__arrowBack {
      margin-top: 20px;
    }
  }
}

@media screen and (max-width: 767px) {
  .CustomerBanner {
    height: 152px;
    justify-content: center;
    align-items: flex-start;
    padding: 0 24px;

    &__arrowBack {
      margin-top: 24px;
    }

    .disconnectButton {
      display: none;
    }

    .customerDropdown {
      display: block;
      align-self: flex-start;
      margin: 20px 0 0 6px;
    }
  }

  .CustomerBanner__name {
    position: absolute;
    top: 100px;
    left: 0;
    width: 100%;
    height: 28px;
    padding: 0 50px !important;
    text-align: center;
    font-size: 20px;
    line-height: 28px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .CustomerBanner__IconPack {
    margin: 0 12px 149px 0;
  }
}
