.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

/* Font color */
.primary {
  color: #191919;
}

.secondary {
  color: #49505a;
}

.tertiary {
  color: #707d8f;
}

.quaternary {
  color: #95a1b2;
}

.white {
  color: #ffffff;
}

.warning {
  color: #e1950d; // #f8b131
}

.error {
  color: #d7282f;
}

.link {
  color: #118af9;
}

/* Font weight */
.normal {
  font-weight: 400;
}

.medium {
  font-weight: 500;
}

.semibold {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

/* Font size */
.xs {
  font-size: 12px;
  line-height: 18px;

  &--margin {
    margin-top: -1px;
    margin-bottom: -1px;
  }
}

.sm {
  font-size: 13px;
  line-height: 22px;

  &--margin {
    margin-top: -3px;
    margin-bottom: -3px;
  }
}

.md {
  font-size: 14px;
  line-height: 24px;

  &--margin {
    margin-top: -3px;
    margin-bottom: -3px;
  }
}

.lg {
  font-size: 16px;
  line-height: 28px;

  &--margin {
    margin-top: -4px;
    margin-bottom: -4px;
  }
}

.xl {
  font-size: 18px;
  line-height: 27px;

  &--margin {
    margin-top: -2px;
    margin-bottom: -2px;
  }
}

.xl-2 {
  font-size: 20px;
  line-height: 28px;

  &--margin {
    margin-top: -2px;
    margin-bottom: -2px;
  }
}

.xl-3 {
  font-size: 24px;
  line-height: 32px;

  &--margin {
    margin-top: -1px;
    margin-bottom: -1px;
  }
}

.xl-4 {
  font-size: 26px;
  line-height: 36px;

  &--margin {
    margin-top: -2px;
    margin-bottom: -2px;
  }
}

.xl-5 {
  font-size: 30px;
  line-height: 40px;

  &--margin {
    margin-top: -2px;
    margin-bottom: -2px;
  }
}

.xl-6 {
  font-size: 36px;
  line-height: 50px;

  &--margin {
    margin-top: -3px;
    margin-bottom: -3px;
  }
}

.xl-7 {
  font-size: 40px;
  line-height: 54px;

  &--margin {
    margin-top: -3px;
    margin-bottom: -3px;
  }
}
