.border-radius-3 {
  border-radius: 3px;
}

.border-radius-5 {
  border-radius: 5px;
}

.border-radius-7 {
  border-radius: 7px;
}

.border-radius-rounded {
  border-radius: 25px;
}

.border-radius-circle {
  border-radius: 50%;
}
