@import "./../../styles/common.scss";

.DatePicker,
.DateRangePicker {
  position: relative;
  width: 100%;
}

.Picker {
  position: absolute;
  top: 50px;
  right: 0;
  box-shadow: 2px 10px 50px #3f4b8021;
  border-radius: 7px;
  background-color: $theme-background-color-13;
  width: 300px;
  height: max-content;
  z-index: $z-index-date-picker;

  @include screen-sm {
    width: max-content;
  }

  &.withLabel {
    top: 80px;
  }
}

.ActionSelect {
  width: auto;
  margin: 20px 20px 0;

  @include screen-sm {
    display: none;
  }
}

.ActionList {
  display: none;

  @include screen-sm {
    display: flex;
    flex-wrap: wrap;
    margin: 30px 20px 0;
  }

  &__item {
    @include font(14px, 29px, 400);
    @include flex-center();
    color: $theme-text-color-4;
    flex: 0 162px;
    height: 45px;
    border-radius: 2px;
    border: 1px solid $theme-border-color-5;
    transition: all 0.2s;
    cursor: pointer;

    @include hover-supported {
      background-color: $theme-background-color-19;
    }

    &--isActive {
      font-weight: 700;
      background-color: $theme-background-color-19;
      color: $theme-text-color-19;
    }
  }
}

.Calendar {
  display: flex;
  flex-direction: column;

  @include screen-sm {
    flex-direction: row;
  }
}

.Controls {
  display: flex;
  justify-content: flex-end;
  padding: 30px 10px 32px;

  &__button {
    max-width: 115px;
    margin: 0 10px;
    border: none;
  }
}

.TimePicker__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 120px;

  @include screen-sm {
    flex-direction: row;
    height: auto;
  }
}

.Divider {
  width: 100%;
  height: 1px;
  background-color: $theme-background-color-7;
  margin: 10px 0 40px;
}

@media screen and (max-width: 350px) {
  .Picker {
    right: -30px;
  }
}

@media screen and (max-width: 767px) {
  .Controls__button {
    min-width: 125px;
  }

  .MobilePicker {
    position: fixed;
    top: 50%;
    left: 50%;
    height: max-content;
    transform: translate(-50%, -50%);
    max-height: calc(100vh - 40px);
    overflow-x: auto;
    overflow-y: overlay;
  }
}
