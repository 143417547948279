@import "../../../styles/common.scss";

.SelectInput {
  height: 75px;

  &__NoOptionsLabel {
    padding-top: 5px;
    color: $theme-text-color-2;
    @include font(14px, 29px, normal);
  }
}

.option {
  min-height: 45px;
  cursor: pointer;
  padding: 0 30px;
  display: flex;
  align-items: center;
  @include hover-supported {
    background-color: #f2f4f8;
  }

  &.isSelected {
    background-color: #dbeefe;
  }
}

.error {
  position: static;
}

.clearIndicator {
  @include hover-supported {
    path {
      fill: #0879e1;
    }
  }
}
