@import "../../../styles/common.scss";

.headRow {
  border-radius: 8px;
}

.toggler {
  padding-left: 12px !important;
  cursor: pointer;
  background: #ffffff;
  border-radius: 8px;
}

.showMore {
  transition: all 0.3s;
  .arrow {
    width: 9px;
    height: 6px;
  }
  &.expanded {
    transform: rotate(180deg);

    .arrow path {
      fill: $theme-fill-color-12;
    }
  }
}
